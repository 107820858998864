import { Box, InputBase, Typography, Button,Select,MenuItem } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers";
import { inputStyles } from "../forms/signup_stepper"

// import { formStyle } from "../modules/profile"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateInventoryForm, updateInventoryFormData } from "../redux/admin";
import CustomAxios from "../utils/CustomAxios";
export const formStyle = {
    inputBase: { width:"100%",border: "1px solid", borderColor: "grey.400", borderRadius: "5px", height: "48px", fontSize: "16px", padding: "14px", color: "grey.700" },
    label:{  fontWeight: 400, fontSize: "14px" ,textAlign:"left",lineHeight:"20px"},
    button: { height: "3rem",padding:"0rem 1.25rem", bgcolor:"primary.light","&:hover":{bgcolor:"primary.light",color:"primary.contrastText"},textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-start",borderRadius:"5px" },
}
const InventoryForm = () => {
    const inventoryFormData = useSelector(state => state.admin.inventoryFormData)
    const selectedInventoryProduct = useSelector(state => state.admin.selectedInventoryProduct)
    const [isLaoding,setIsLoading]=useState(true)
    const dispatch = useDispatch()
    const [validation, setValidation] = useState(false)
    const cancelForm = () => {
        dispatch(updateInventoryFormData({}))
        dispatch(updateInventoryForm(false))
        // setExpiredDtate("")
        // setPurchaseDate("")
        // setBatchNumber("")
        // setQuantity("")

    }
    const fromValidation = () => {
        //    let validation=false
        if (inventoryFormData.invoice_no && inventoryFormData.inward_quantity && inventoryFormData.expiry_date && inventoryFormData.invoice_date &&inventoryFormData.batch_number&&inventoryFormData.expiry_alert&&inventoryFormData.taxable_amt&&inventoryFormData.price_per_pcs&&inventoryFormData.net_amt&&inventoryFormData.inward_quantity&&inventoryFormData.gst_no&&inventoryFormData.invoice_date&&inventoryFormData.purchase_date&&inventoryFormData.invoice_no&&inventoryFormData.purchase_company&&inventoryFormData.total_gst&&inventoryFormData.gst_percentage&&inventoryFormData.sgst&&inventoryFormData.cgst&&inventoryFormData.expiry_date) {
            return true
        }
        else {
            return false
        }

    }
    useEffect(() => {
        setValidation(fromValidation())
    }, [inventoryFormData])
    const handleSubmit = () => {
        setIsLoading(true)
        const payload = { ...inventoryFormData, products: selectedInventoryProduct.id }
        dispatch(updateInventoryFormData({ ...inventoryFormData, products: selectedInventoryProduct.id }))
        // console.log(inventoryFormData)
        if (inventoryFormData.id==undefined){
            console.log("inside creating")
            CustomAxios.post('inventory-history/', payload,)
            .then(result => {
                if (result.status == 201) {
                    dispatch(updateInventoryForm(false))

                }
            })
            .catch(error => console.log(error))
        }
        else{
            console.log("inside updating")
            CustomAxios.put(`inventory-history/${inventoryFormData.id}`, payload,)
            .then(result => {
                if (result.status == 201) {
                    dispatch(updateInventoryForm(false))

                }
            })
            .catch(error => console.log(error))
        }
       
    }
    const handlePlaceholder = (val,placeholder)=>{
        // console.log(val,placeholder)
        // const newDate = new Date(date);
        // newDate.setDate(date.getDate() + days);
        
        if(!val){
            // dispatch(updateInventoryForm(...inventoryFormData,inventoryFormData.expiry_alert:))
          return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
        }
        
        return val
      }

    return <Box sx={{
        position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%, -50%)',
        flexDirection: "column",
        bgcolor: "#fff",
        // height: "150px",
        // maxWidth: "500px",
        width: { sm: "60%", xs: "100%" },
    }}>
        <Box sx={{ bgcolor: "#F8F9FF", padding: "20px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "600", }}>
               {inventoryFormData.id?"Edit":"Add"}
            </Typography>

        </Box>
        <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" ,height:"400px",overflow:"scroll"}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "36px", justifyContent: "space-between" }}>
                <Box>
                    <Typography sx={formStyle.label}>Batch No<span style={{ color: "#FF5449" }} >*</span></Typography>
                    <InputBase sx={formStyle.inputBase} placeholder="000x-yyy" value={inventoryFormData.batch_number} onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, batch_number: e.target.value }))} />

                </Box>

            </Box>
            <Box sx={{display:"flex",flexDirection:"column",gap:"12px"}}>
                <Typography sx={{fontSize:"14px",fontWeight:600,lineHeight:"20px"}}>Invoice Details</Typography>
                <Box sx={{display:"flex",justifyContent:"space-between",gap:"16px"}}>


                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> Invoice Number<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.invoice_no} placeholder="Enter Invoice Number" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, invoice_no: e.target.value }))} />
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}>Invoice Date <span style={{ color: "#FF5449" }} >*</span></Typography>
                        <DatePicker
                            //   value={Dob}
                            value={inventoryFormData.invoice_date}
                            onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, invoice_date: e }))}
                            //   disabled={user_data.isVerifyTick}
                            // disabled={!iskyceditable}
                            className="pan-datepicker"
                            disableFuture
                            format="DD/MM/YYYY"
                            sx={{
                                "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root":
                                    { ...formStyle.inputBase, border: "none" },
                            }}
                        />
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> Purchase Company<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.purchase_company} placeholder="Enter Purchase Company" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, purchase_company: e.target.value }))} />
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> GST Number<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.gst_no} placeholder="Enter GST Number" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, gst_no: e.target.value }))} />
                    </Box>
                </Box>

            </Box>
            <Box sx={{display:"flex",flexDirection:"column",gap:"12px"}}>
                <Typography sx={{fontSize:"14px",fontWeight:600,lineHeight:"20px"}}>Product Details</Typography>
                <Box  sx={{display:"flex",justifyContent:"space-between",gap:"16px"}}>


                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> Product Per Piece<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.price_per_pcs} placeholder="Enter Product/Piece" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, price_per_pcs: e.target.value.replace(/\D/g, "") }))} />
                    </Box>

                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> Inward Quantity<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.inward_quantity} placeholder="Enter Quantity" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, inward_quantity: e.target.value.replace(/\D/g, "") }))} />
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}>Purchased Date <span style={{ color: "#FF5449" }} >*</span></Typography>
                        <DatePicker
                            //   value={Dob}
                            value={inventoryFormData.purchase_date}
                            onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, purchase_date: e }))}
                            //   disabled={user_data.isVerifyTick}
                            // disabled={!iskyceditable}
                            className="pan-datepicker"
                            disableFuture
                            format="DD/MM/YYYY"
                            sx={{
                                "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root":
                                    { ...formStyle.inputBase, border: "none" },
                            }}
                        />
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}>Expired On<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <DatePicker
                            //   value={Dob}
                            value={inventoryFormData.expiry_date}
                            onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, expiry_date: e }))}
                            //   disabled={user_data.isVerifyTick}
                            // disabled={!iskyceditable}
                            className="pan-datepicker"
                            disablePast
                            format="DD/MM/YYYY"
                            sx={{
                                "&.pan-datepicker .MuiInputBase-root.MuiOutlinedInput-root":
                                    { ...formStyle.inputBase, border: "none" },
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",gap:"12px"}}>
                <Typography sx={{fontSize:"14px",fontWeight:600,lineHeight:"20px"}}>Financial Summary</Typography>
                <Box  sx={{display:"flex",justifyContent:"space-between",gap:"16px"}}>


                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> Taxable Amount<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.taxable_amt} placeholder="Enter Taxable Amount" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, taxable_amt: e.target.value.replace(/\D/g, "") }))} />
                    </Box>

                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}>Gst Percentage<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.gst_percentage} placeholder="Enter GST Percentage" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, gst_percentage: e.target.value.replace(/\D/g, "") }))} />
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> SGST<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.sgst} placeholder="Enter SGST" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, sgst: e.target.value.replace(/\D/g, "") }))} />
                    </Box>
                    <Box sx={{width:"25%"}}>
                        <Typography sx={formStyle.label}> CGST<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.cgst} placeholder="Enter CGST" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, cgst: e.target.value.replace(/\D/g, "") }))} />
                    </Box>
                </Box>
                <Box  sx={{display:"flex",gap:"16px"}}>
                    <Box sx={{width:"24%"}}>
                        <Typography sx={formStyle.label}> Total GST<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.total_gst} placeholder="Enter Total GST" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, total_gst: e.target.value.replace(/\D/g, "") }))} />
                    </Box>
                    <Box sx={{width:"24%"}}>
                        <Typography sx={formStyle.label}> Net Amount<span style={{ color: "#FF5449" }} >*</span></Typography>
                        <InputBase sx={formStyle.inputBase} value={inventoryFormData.net_amt} placeholder="Enter Net Amount" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, net_amt: e.target.value.replace(/\D/g, "") }))} />
                    </Box>

                </Box>

            </Box>
            <Box sx={{display:"flex",flexDirection:"column",gap:"12px"}}>
                <Typography sx={{fontSize:"14px",fontWeight:600,lineHeight:"20px"}}>Stock Alert</Typography>
                <Box  sx={{display:"flex",justifyContent:"space-between",gap:"16px"}}>


                    <Box sx={{width:"24%"}}>
                        <Typography sx={formStyle.label}> Set Expire Alert<span style={{ color: "#FF5449" }} >*</span></Typography>
                        {/* <InputBase sx={formStyle.inputBase} value={inventoryFormData.expiry_alert} placeholder="##" onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, expiry_alert: e.target.value.replace(/\D/g, "") }))} /> */}
                        <Select displayEmpty value={inventoryFormData.expiry_alert} sx={{ gap: "8px",borderColor: "grey.400", borderRadius: "5px",  height: "48px", width: "100%", "&:hover":{borderColor:"grey.400",},"&:focus":{borderColor:"grey.400"} }} onChange={(e) => dispatch(updateInventoryFormData({ ...inventoryFormData, expiry_alert: e.target.value}))} renderValue={(val) => handlePlaceholder(val, 'Select Expiry Alert')}>
                            {/* <MenuItem value="">All</MenuItem> */}
                            <MenuItem value='1 Week'>1 Week</MenuItem>
                            <MenuItem value='1 Month'>1 Month</MenuItem>
                            <MenuItem value='3 Months'>3 Month</MenuItem>
                            {/* <MenuItem value="draft">Draft</MenuItem> */}
                        </Select>
                    </Box>
                </Box>
            </Box>

        </Box>
        <Box sx={{ bgcolor: "#F8F9FF", padding: "20px", display: "flex", justifyContent: "flex-end", gap: "16px" }}>
            <Button sx={{textTransform:"none"}} variant="outlined" onClick={cancelForm} >Cancel</Button>
            <Button  sx={formStyle.button} variant="contained" disabled={!validation && isLaoding} onClick={handleSubmit}> Submit</Button>

        </Box>
    </Box>
}

export default InventoryForm