import * as React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { BrowserRouter, Routes, Route, useNavigate, Navigate, useLocation, Outlet, useParams, } from 'react-router-dom';
import Home from './modules/home';
import { Provider, useSelector } from 'react-redux';
import store from './redux/index';
import Signup from './modules/signup';
import Login from './modules/login';
import ForgotPassword from './modules/forgot_password';
import PasswordChange from './modules/password_change';
import OtpVerify from './modules/otp_verify';
import Profile from './modules/profile';
import Product from './modules/products';
import CommisionHistory from './modules/commision_history';
import AddProducts from './modules/addProducts';
// import { Inventory } from '@mui/icons-material';
import Checkout from './modules/checkout';
import Inventory from './modules/Inventory';
import Orders from './modules/orders';
import ProductDetails from './modules/productDetails';
import OrdersStatus from './modules/order_status';
import Dashboard from './modules/dashboard';
import { jwtDecode } from 'jwt-decode';
import { useState,useEffect } from 'react'
import { Router } from '@mui/icons-material';
import Invoice from './components/invoice';
import Settings from './modules/adminsettings';
import { StaticWebPage } from './staticPageComponents/staticPage';

import CulturePage from './staticPageComponents/culturepage';
import { FinancialSystem } from './staticPageComponents/financialSystem';
import RefundPolicy from './staticPageComponents/refund_policy';
import PrivacyPolicy from './staticPageComponents/privacy_policy';
import TermsOfService from './staticPageComponents/terms_services';
import ShippingPolicy from './staticPageComponents/shipping_policy';
import { LandingPage } from './landing_page/landing_page';
import { MyWallet } from './modules/my_wallet';
import WithdrawView from './modules/withdrawAdminView';
import  OtpSignPin  from './components/otp_verify_signup';
import Stocklist from './modules/stocklist';
import Stockbatch from './modules/stockbatch';
export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

// const authToken=localStorage.getItem("authToken")
// const Authorize = () => {
//   const location = useLocation()
//   // console.log(authToken,role)
//   if(!authToken || role ){
//     return <Navigate to="/login"/>
//   }
//   return <Outlet/>
// }

const CheckProductDetails = ()=>{
  const products = useSelector(state=>state.product.Allproducts)
  const params = useParams()
  // // console.log(params)
  if(Object.keys(products).length === 0 && !isNaN(params.id)){
    return <Navigate to='/product'/>
  }
  return <Outlet/>
}

const CheckOrderDetails = ()=>{
  const orders = useSelector(state=>state.orders.AllOrders)
  const params = useParams()
  // // console.log(params,orders.length)
  if(Object.keys(orders).length === 0){
    return <Navigate to='/orders'/>
  }
  return <Outlet/>
}


function MyApp() {
  const authToken=localStorage.getItem('authToken');
  const refreshToken=localStorage.getItem('refreshToken');
  let  username = useSelector(state => state.user.username)
  // // console.log(authToken, refreshToken)
  // const authToken = localStorage.getItem("authToken")
  // const navigate=useNavigate()
  const [admin,setAdmin]=useState(false)
  // // console.log(product)
  useEffect(()=>{
      if (authToken){
          const decode=jwtDecode(authToken)
          if(decode.role=='admin'){
            setAdmin(true)
          }
      }
     
  },[])
  // const navigate = useNavigate();
  return (
    <BrowserRouter>
      <Routes>
      <Route exact path="/our_product" element={<Home />} />
        <Route path="/signup" element={<Signup/>} />
        <Route path='/login' element={<Login/>}/>
        <Route path="/otp-verify-signup" element={<OtpSignPin/>} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/password-change/:id" element={<PasswordChange/>} />
        <Route path="/culture" element={<CulturePage/>} />

      
        <Route path="/otp-verification" element={<OtpVerify /> }/>
        <Route path="/profile" element={username!="" || authToken && refreshToken ? <Profile/> : <Navigate to="/login" /> }/>
        <Route path="/product" element={username!="" || authToken && admin && refreshToken ? <Product /> : <Navigate to="/login" /> }/>
        <Route path="/commision-history" element={username!="" || authToken && admin && refreshToken ? <CommisionHistory /> : <Navigate to="/login" /> }/>
        <Route path="/Withdraw-request" element={username!="" || authToken && admin && refreshToken ? <WithdrawView /> : <Navigate to="/login" /> }/>
        <Route path="/dashboard" element={username!="" || authToken && refreshToken ? <Dashboard /> : <Navigate to="/login" /> }/>
        <Route element={<CheckProductDetails/>}>
          <Route path="/product/:id" element={username!="" || authToken && admin && refreshToken ? <AddProducts /> : <Navigate to="/login" />} />
        </Route>
        <Route path="/inventry" element={username!="" || authToken && admin && refreshToken ? <Inventory /> : <Navigate to="/login" />}/>
        <Route path="/stocklist" element={username!="" || authToken && admin && refreshToken ? <Stocklist /> : <Navigate to="/login" />}/>
        <Route path="/stocklist/:id" element={username!="" || authToken && admin && refreshToken ? <Stockbatch /> : <Navigate to="/login" />}/>

        <Route path="/orders" element={username!="" || authToken  && admin && refreshToken ? <Orders /> : <Navigate to="/login" />}/>
        <Route path="/orderstatus" element={username!="" || authToken && refreshToken ?<OrdersStatus /> : <Navigate to="/login" />}/>
        <Route path="/checkout" element={username!="" || authToken && refreshToken ?<Checkout /> : <Navigate to="/login" />}/>
        <Route path="/product_details/:id" element={username!="" || authToken && refreshToken ?<ProductDetails /> : <Navigate to="/login" />} />
        <Route element={<CheckOrderDetails/>}>
          <Route path="/orders/:orderId" element={username!="" || authToken && refreshToken ? <Orders /> : <Navigate to="/login" />}/>
        </Route>
        {/* </Route> */}
        <Route path="/invoice" element={<Invoice/>}></Route>
        <Route path="/delivery-settings" element={username!="" || authToken && refreshToken ? <Settings/> : <Navigate to="/login" />}></Route>
        <Route path="/" element={<LandingPage/> }></Route>
        <Route path="/Financial_system" element={<FinancialSystem/> }></Route>
        <Route path="/refund-policy" element={<RefundPolicy/> }></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy/> }></Route>
        <Route path="/terms-service" element={<TermsOfService/> }></Route>
        <Route path="/shipping-policy" element={<ShippingPolicy/> }></Route>
        <Route path="/my_networth" element={username!="" || authToken && refreshToken ? <MyWallet/> : <Navigate to="/login" />}></Route>
      </Routes>
    </BrowserRouter>

  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({"palette":{"common":{"black":"rgba(36, 36, 36, 1)","white":"#fff"},"background":{"paper":"#fff","default":"#fff"},"primary":{"light":"rgb(39,162,253,1)","main":"rgba(39, 161, 255, 1)","dark":"rgba(0, 113, 197, 1)","contrastText":"rgba(255, 255, 255, 1)"},"secondary":{"light":"rgba(103, 251, 168, 1)","main":"rgba(3, 218, 101, 1)","dark":"rgba(2, 159, 72, 1)","contrastText":"#fff","grey":"#EDF1F9","greyDark":"#6F7883","greyBg":"#F8F9FF"},"error":{"light":"rgba(255, 122, 122, 1)","main":"rgba(245, 39, 24, 1)","dark":"rgba(201, 22, 22, 1)","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.65)","disabled":"rgba(24, 23, 23, 0.49)","hint":"rgba(0, 0, 0, 0.25)"}},typography: {
        fontFamily: 'Poppins',
      }}),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
        <MyApp />
        </Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}