import { Box, Button, Divider, InputBase, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import SideBarList from "../components/list";
import MessageBar from "./messageBar";
import { useMediaQuery } from "react-responsive";
import Layout from "../layout";
import walletbg from "../assets/walletbg.png";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomAxios from "../utils/CustomAxios";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import { inputStyles } from "../forms/signup_stepper";
import SearchIcon from '@mui/icons-material/Search';
import add from "../assets/add.svg"
import compare from "../assets/compare_arrows.svg"
import moneyWithdraw from "../assets/money-bill.svg"
import { updateMemberResponse, updateSelecteIdForTransaction, updateTransactionPinModal, updateTransactionPinVerification,updateGetBank, updateOpenWithdrawRequest,updateWithdrawRequest, updateWithdrawDisabled, updateWithdraw, updateWithdrawAmountChange, updateWithdrawAmount, updateWithdrawAccount, updateOtpVerifyPopup,updateOtpCheck, updateIsProfileReloaded,updateWhatsapp } from "../redux/user_reducer";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import CountUp from 'react-countup';
import Tooltip from '@mui/material/Tooltip';
import { Redirection } from "../components/Redirections";
import { TransactionsAndWithdrawals } from "../components/transactionsHistory & withdrawal";
import { load } from "@cashfreepayments/cashfree-js";
import WithdrawForm from "../components/withdrawForm";
import OTP_pop from "../components/otp_popup"
export const styles = {
        image: { height: "151px", width: "100%", backgroundImage: `url(${walletbg})` },
        headings: { fontWeight: "500", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "black" },
        tabColor: { color: "primary.light", fontWeight: "700", indicatorColor: "primary.light", fontSize: "16px", lineHeight: "24px" },
        inputbase: { maxWidth: "481px", width: "100%", height: "36px", padding: "8px 12px", border: "1px solid grey", gap: "8px", borderradius: "10px" }
    }
export function MyWallet() {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLaptop = useMediaQuery({ query: '(max-width: 900px)' });
    const isTabletHori = useMediaQuery({ query: '(min-width: 1200px)' });
    const [transferModalOpen, setTransferModalOpen] = useState(false)
    const [isMCTransferClicked, setIsMCTransferClicked] = useState(false)
    const [isMETransferClicked, setIsMETransferClicked] = useState(false)
    const [Transferamt, setTransferAmt] = useState(0.00)
    const [ReUsername, setRecUsername] = useState(null)
    const [searchedUser, setSearchUser] = useState("")
    const [transactionSuccess, setTransactionSuccess] = useState(false)
    const [transfer, setTransfer] = useState(false)
    const [McMe, setMcMe] = useState("")
    const [usersSearched, setUsersSearched] = useState()
    const [isloading, setIsloading] = useState(false)
    const selecteIdForTransaction = useSelector(state => state.user.selecteIdForTransaction)
    const transactionpinVerification = useSelector(state => state.user.transactionpinVerification)
    const [userid, setUserId] = useState('')
    const [imagestatus, setImageStatus] = useState(false)
    const [addMoneyModalOpen, setAddMoneyModalOpen] = useState(false)
    const [amountTobeAdded, setAmountTobeAdded] = useState()
    const [transferType, setTransferType] = useState("")
    const [lastTransaction, setLastTransaction] = useState("")
    const [yetTorelease, setYettoRelease] = useState(0)
    const [userreponse, setUserResponse] = useState({})
    const withdrawAmountChange=useSelector(state=>state.user.withdrawAmountChange)
    const [validations, setValidations] = useState({ })
    const userbal = useSelector(state => state.user.accumulated)
    const userbalmodal = useSelector(state => state.user.transactionPinModal)
    let id = parseInt(useSelector(state => state.user.id))
    let profile = useSelector(state => state.user.profile)
    let res = useSelector(state => state.user.member_response)
    let withdrawId=useSelector(state=>state.user.withdrawId)
    let withdrawRequest=useSelector(state=>state.user.withdraRequest)
    const withdraw = useSelector( state => state.user.withdraw)
    const milestone = ["bronze","silver","gold","platinum","diamond"]
    const withdrawDisabled = useSelector(state=>state.user.withdrawDisabled)
    const overallSpend = useSelector(state=>state.user.overallSpend)
    const withdrawEligibility = {
        'bronze': 1120,
        'silver': 3360,
        'gold': 11200,
        'platinum': 56000,
        'diamond': 112000,
      };
    const currentMilestone = userreponse?.account?.milestone_id;
    const Milestone_completed = userreponse?.account?.milestone_completed;
    let accumulatedData = 0
    useEffect(()=>{
        console.log(userbal)
        if(Object.keys(userbal).length >0){
            accumulatedData = milestone
            .slice(currentMilestone-1) // Skip the first `skipCount` items
            .reduce((sum, level) => {
                const value = userbal[level];
                const eligibility = withdrawEligibility[level];
                // console.log(Milestone_completed , eligibility)
            if (value !== undefined && eligibility !== undefined && Milestone_completed < eligibility) {
                return sum + userbal[level];
            }
            return sum;
            }, 0);
            // console.log(accumulatedData)
            setYettoRelease(accumulatedData.toFixed(2))
        }
        
    },[userbal,userreponse])

    let withdrawAmount=useSelector(state=>state.user.withdrawAmount)
    let withdrawAccount=useSelector(state=>state.user.withdrawAccount)
    
    const navigate = useNavigate()
    useEffect(() => {
        setUserResponse(res)

        const lastTransaction = res?.wallet_history?.filter(item => item.status === 'completed' || item.status === 'paid')
        .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
        .map(item => ({
            ...item,
        }))[0]; 

    if (lastTransaction) {
        setLastTransaction((lastTransaction.amount).toFixed(2)); // Set the last transaction as the only item in the array
    }
    }, [res])
    useEffect(() => {
        //// console.log(id)
        setUserId(id)
    }, [id])
    const dispatch = useDispatch()
   

    const handleTransfer = (val) => {
        // setTransferModalOpen(false)

        dispatch(updateTransactionPinModal(true))
    }
    useEffect(()=>{
        const fetchuserdetails = async () => {
            // // console.log("id", id)
            const payload = {
                user_id: id
            }
        await CustomAxios.post(`get_details/`, payload).then(response => {
            dispatch(updateMemberResponse(response.data))
            dispatch(updateGetBank(response.data.bankdetails))
            dispatch(updateWhatsapp(response.data.user.whatsapp))
            dispatch(updateWithdrawRequest(response.data.withdrawRequest))
            setTransactionSuccess(false)
        }
        ).catch(error => {
            // console.log(error)
        })
    }
    fetchuserdetails()
    },[transactionSuccess])
    useEffect(()=>{


        if(transactionpinVerification =="verified" && !withdraw && !withdrawAmountChange && transfer){
        const transferAmount = () => {
            setIsloading(true)
            CustomAxios.post('transfer-funds/', {
                amount: Transferamt,
                recipient_username: McMe === 'me' ? userreponse?.user.username : usersSearched?.user.username,
                user_id: id,
                tf: McMe
            }).then((res) => {
                if (res.status == 200) {
                    dispatch(updateIsProfileReloaded(true))
                    dispatch(updateSnackBarSeverity('success'))
                    dispatch(updateSnackBarMessage(res?.data?.message))
                    dispatch(updateSnackBarOpen(true))
                    handleClearTransactionStates()
                    setTransferModalOpen(false)
                    setTransactionSuccess(true)
                    setTransfer(false)
                    setIsloading(false)


                }
                else {
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarMessage(res.data.message))
                    dispatch(updateSnackBarOpen(true))
                    setTransactionSuccess(false)
                    setTransfer(false)
                    handleClearTransactionStates()
                    setIsloading(false)
                }
            }).catch((err) => {
                dispatch(updateSnackBarSeverity('error'))
                dispatch(updateSnackBarMessage(err?.response?.data?.message?.toString()))
                dispatch(updateSnackBarOpen(true))
                handleClearTransactionStates()
                setIsloading(false)

            })
    
            setIsMCTransferClicked(false)
            setIsMETransferClicked(false)
            setTransferAmt(0.00)
            setRecUsername(null)
            setIsloading(false)
        }
        transferAmount()}
        else if(transactionpinVerification =="verified" && withdraw&& !withdrawAmountChange){
            console.log(userbal.withdrawal_available)
            dispatch(updateOtpVerifyPopup(true))
            dispatch(updateOtpCheck("withdraw_request"))
            
        }
        else if(transactionpinVerification =="verified" && withdraw && withdrawAmountChange){
            console.log("update withdraw")
            dispatch(updateOtpVerifyPopup(true))
            dispatch(updateOtpCheck("withdraw_update"))
        }
    },[transactionpinVerification])
   
    const addCurrencyAndCommafy = (num) => {
        // // console.log(num)

        let str = parseFloat(num).toFixed(2).toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        return "₹ " + str.join('.');

    }
    const handleEnter = (e) => {
        if (e.key == 'Enter') {
            setMcMe('mc')
            // setIsloading(true)
            CustomAxios.get(`get_user/${searchedUser}`).then((res) => { 
                console.log(res)
                if(res.data.message == "success"){
                    setUsersSearched(res.data);
                    if(res.data.attachments?.original){
                        setImageStatus(true)
                    }else{
                        setImageStatus(false)
                    }
                }else{
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarMessage(res.data.message))
                    dispatch(updateSnackBarOpen(true))
                }})
            
        }
    }
    const handleGetuser = (e) => {
        if (searchedUser) {
            setMcMe('mc')
            CustomAxios.get(`get_user/${searchedUser}`).then((res) => { 
                console.log(res)
                if(res.data.message == "success"){
                    setUsersSearched(res.data);
                    if(res.data.attachments?.original){
                        setImageStatus(true)
                    }else{
                        setImageStatus(false)
                    }
                }else{
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarMessage(res.data.message))
                    dispatch(updateSnackBarOpen(true))
                }})}
    }
    const handleEnterAmount = (e) => {
        if (e.key == 'Enter') {
           handlePayment()
        }
    }
    const [radioValue, setRadioValue] = useState('');

    const handleChangeRadio = (event) => {
        if(event.target.value === 'To self'){
            setIsMETransferClicked(true)
            setMcMe('me')
            setValidations({ })
            setTransferAmt(0.00)
            dispatch(updateSelecteIdForTransaction(""))
            setRadioValue(event.target.value);
        }
        if(event.target.value === 'To others'){
            setIsMCTransferClicked(true)
            setMcMe('mc')
            setValidations({ })
            setTransferAmt(0.00)
            dispatch(updateSelecteIdForTransaction(""))
            setRadioValue(event.target.value);
        }

    };
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    const handleChangeSelf = (event) => {
        console.log(event.target.value)
        dispatch(updateSelecteIdForTransaction(event.target.value));
    };
    const capitalize = (str) => {
        return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };
    const handleClearTransactionStates=() =>{
        dispatch(updateSelecteIdForTransaction(""))
        setRadioValue("To others")
        dispatch(updateTransactionPinVerification(""))
        setTransferType("")
        setTransactionSuccess(false)
        setUsersSearched()
        setSearchUser("")
    }
    const handlecloseModal=() =>{
        handleClearTransactionStates()
        setTransferModalOpen(false)
    }
    const handlecloseAddmoneyModal=() =>{
        setAddMoneyModalOpen(false)
    }
    useEffect(()=>{
        console.log(transferType)
        if(transferType == "self"){
            setValidations({ })
            setTransferAmt(0.00)
            setRadioValue("To self")
        }else{
            setTransferAmt(0.00)
            setValidations({ })
            setRadioValue("To others")
        }
    },[transferType])
    const handleTransferAmount = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (!isNaN(inputValue) && inputValue>0 && transferType == "To others"&&inputValue <= parseFloat(userreponse?.mc?.balance)) {
            setValidations({ });
            setTransferAmt(inputValue);
        }else if (!isNaN(inputValue) && inputValue>0 && transferType == "self"&&inputValue <= parseFloat(userreponse?.accumulated?.withdrawal_available)) {
            setValidations({ });
            setTransferAmt(inputValue);
        } 
        else {
            setValidations({ internalTransferAmount: "Please provide the available earnings amount to transfer." });
        }
    };
    useEffect(()=>{
        if(userreponse?.att?.original){
          setImageStatus(true)
        }
      },[userreponse])

      const handlePayment = async()=>{
        let bodyData = new FormData();
        bodyData.append("amount", parseFloat(amountTobeAdded * 1.0231).toFixed(2).toString());
        bodyData.append("user_id", userid);
        bodyData.append("transaction_fee",parseFloat(amountTobeAdded*0.0231).toFixed(2).toString())
        
        const data = await CustomAxios.post('initiate-add-funds/',bodyData).then((res)=>{return res.data})

        let cashfree;    
        cashfree = await load({
            mode: process.env.REACT_ENV == "production"?"production":"sandbox"
        });
        let checkoutOptions = {
            paymentSessionId: data.payment_session_id,
            redirectTarget: "_modal",
        };
        cashfree.checkout(checkoutOptions).then((result) => {
            if(result.error){
                // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
                console.log("User has closed the popup or there is some payment error, Check for Payment Status");
                console.log(result.error);
            }
            if(result.redirect){
                // This will be true when the payment redirection page couldnt be opened in the same window
                // This is an exceptional case only when the page is opened inside an inAppBrowser
                // In this case the customer will be redirected to return url once payment is completed
                console.log("Payment will be redirected");
            }
            if(result.paymentDetails){
                // This will be called whenever the payment is completed irrespective of transaction status
                console.log("Payment has been completed, Check for Payment Status");
                console.log(result);
                handlePaymentSuccess(data);

            }
        });
    }

    const handlePaymentSuccess = async(response)=>{
        let bodyData = new FormData();
        bodyData.append("response", JSON.stringify(response));

        CustomAxios.post('add-funds/',bodyData).then((res)=>{
            if(res.status == 200){
                dispatch(updateSnackBarSeverity('success'))
                dispatch(updateSnackBarMessage(res.data.message))
                dispatch(updateSnackBarOpen(true))
                setAddMoneyModalOpen(false)
                setAmountTobeAdded()
                setTransactionSuccess(true)
            }
            else{
                dispatch(updateSnackBarSeverity('error'))
                dispatch(updateSnackBarMessage(res.data.message))
                dispatch(updateSnackBarOpen(true))
            }
        })
    }
    useEffect(()=>{
        console.log("??????????????????withdraw request",withdrawRequest)
        if(withdrawRequest){
            console.log("??????????????????withdraw request",withdrawRequest)
            // let array=[]
        let array=withdrawRequest?.filter(item=>item.status=="requested"||item.status=="inprogress" )
        // console.log(array)
        if(array.length>0){
            dispatch(updateWithdrawDisabled(true))
        }

        }
        
        
    },[withdrawRequest])


//  console.log("?????????????????/update status",withdrawRequest?.filter(item=>item.status=="requested").length>0)
    return (<Box sx={{ bgcolor: isMobile ? "#fff" : "grey.100",  height: "100vh", display: "flex", alignItems: "start", justifyContent: "start",overflow:"auto" }}>
        <Layout />
        <MessageBar />

        <Box sx={{ alignSelf: "start", marginTop: { xs: "77px", md: "140px" }, marginBottom: "100px", justifySelf: "center", display: "flex", justifyContent: "center", width: "100%", gap: "36px" }}>
            {isTabletHori && <Paper sx={{ height: "max-content", width: "20%", position: "sticky", top: "140px" }} elevation={1}>
                {SideBarList('left')}
            </Paper>}
            <Box sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: "12px", width: { xs: "100%", md: "95%", lg: "65%" }, background: "#FFF" }}>
                <Typography sx={styles.headings}>My Networth</Typography>

                <Box sx={{ width: "100%", background: " linear-gradient(90deg, #00629F 0%, #0096F0 100%)", height: "44px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "8px 16px" }}>
                    <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "20px", letterSpacing: "0.1px", color: "white" }}>Available balance</Typography>
                    <Typography sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "20px", letterSpacing: "0.1px", color: "white" }}>{!userreponse.pv ? "0.00" : userreponse?.mc?addCurrencyAndCommafy(userreponse?.accumulated?.withdrawal_available + userreponse?.mc?.balance):"0.00"}</Typography>
                </Box>
                <Box sx={{ padding: "12px", display: "flex", gap: "12px", width: "100%",flexDirection:{xs:"column",md:"row"} }}>
                    <Box sx={{ width:{xs:"100%",md:"50%"}, background: "radial-gradient(96.67% 96.67% at 47.15% 9.39%, #5BC658 0%, #53AEFF 100%)" }}>
                            {(!userreponse?.mc)?<Box sx={{padding:"24px"}}><Skeleton  animation="wave"  height="60px"/>
                            <Skeleton animation="wave"   height="15px"/>
                            <Skeleton animation={false}  height="15px"/></Box>:<>
                        <Box sx={{ padding: "16px 0px", display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center",gap:"8px" }}>
                                <Typography sx={{ fontWeight: "400", fontSize: "16px", lineHeight: "11px", letterSpacing: "0.1px", color: "white" }}>Mayi Pay</Typography>
                                <Typography sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px", letterSpacing: "0.1px", color: "white" }}>₹ 
                                <CountUp
                                    end={userreponse?.mc?.balance}
                                    duration={.5}
                                    decimals={2}
                                />
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%",gap:"8px"  }}>
                                    <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "11px", letterSpacing: "0.1px", color: "white" }}>Last transaction</Typography>
                                    <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "20px", letterSpacing: "0.1px", color: "white" }}>{lastTransaction==""?"0.00":lastTransaction}</Typography>
                                </Box>
                                <Divider orientation='vertical' sx={{ border: "1px solid white", height: "31px" }} />
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%",gap:"8px"  }}>
                                    <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "11px", letterSpacing: "0.1px", color: "white" }}>Overall Spend</Typography>
                                    <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "20px", letterSpacing: "0.1px", color: "white" }}>{overallSpend!==""?overallSpend?.toFixed(2):0.00}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Tooltip title="Coming Soon"> */}
                        <Box sx={{ display: "flex", width: "100%" }} >
                            <Button  sx={{ width: "50%", padding: "8px 12px", height: "36px", bgcolor: "#3F47524D", '&:hover': { bgcolor: "#3F47524D" }, display: "flex", gap: "8px" }} onClick={()=>setAddMoneyModalOpen(true)}>
                                <img src={add} alt="add icon" />
                                <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.1px", color: "white", textTransform: "none" }}> Add Money</Typography>
                            </Button> 
                            <Divider orientation='vertical' sx={{ border: "1px solid transparent" }} />
                            <Button sx={{ width: "50%", padding: "8px 12px", height: "36px", bgcolor: "#3F47524D", '&:hover': { bgcolor: "#3F47524D" }, display: "flex", gap: "8px" }} onClick={() => { setTransferType("To others");setIsMCTransferClicked(true);setIsMETransferClicked(false); setTransferModalOpen(true);setTransfer(true) }}>
                                <img src={compare} alt="add icon" />
                                <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.1px", color: "white", textTransform: "none" }}>Transfer</Typography>
                            </Button>
                            </Box>
                            {/* </Tooltip> */}
                        </>}
                    </Box>
                    <Box sx={{ width: {xs:"100%",md:"50%"}, background: "radial-gradient(96.67% 96.67% at 47.15% 9.39%, #B01F8D 0%, #F9B938 100%)" }}>
                    {(!userreponse?.mc)?<Box sx={{padding:"24px"}}><Skeleton animation="wave"  height="60px"/>
                            <Skeleton animation="wave"   height="15px"/>
                            <Skeleton animation={false}  height="15px"/></Box>:<>
                        <Box sx={{ padding: "16px 0px", display: "flex", flexDirection: "column", gap: "16px" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center",gap:"8px"  }}>
                                <Typography sx={{ fontWeight: "400", fontSize: "16px", lineHeight: "11px", letterSpacing: "0.1px", color: "white" }}>Mayi Earnings</Typography>
                                <Typography sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px", letterSpacing: "0.1px", color: "white" }}>₹ 
                                <CountUp
                                    end={userreponse?.accumulated?.withdrawal_available}
                                    duration={.5}
                                    decimals={2}
                                /></Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%",gap:"8px"  }}>
                                    <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "11px", letterSpacing: "0.1px", color: "white" }}>Yet to release</Typography>
                                    <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "20px", letterSpacing: "0.1px", color: "white" }}>{yetTorelease}</Typography>
                                </Box>
                                <Divider orientation='vertical' sx={{ border: "1px solid white", height: "31px" }} />
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%",gap:"8px"  }}>
                                    <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "11px", letterSpacing: "0.1px", color: "white" }}>Overall Earnings</Typography>
                                    <Typography sx={{ fontWeight: "500", fontSize: "18px", lineHeight: "20px", letterSpacing: "0.1px", color: "white" }}> {userreponse?.accumulated?.total_accumulation}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", width: "100%" }}>
                            {withdrawDisabled|| userreponse?.accumulated?.withdrawal_available<=0?
                            <Tooltip title={userreponse?.accumulated?.withdrawal_available<=0 ?"": "Your Request is Already Inprogress. Please check in your withdraw history."}><Button sx={{ width: "100%", padding: "8px 12px", height: "36px", bgcolor: "#3F47524D", '&:hover': { bgcolor: "#3F47524D",cursor:"not-allowed" }, display: "flex", gap: "8px" ,cursor:"not-allowed"}} >
                            <img src={moneyWithdraw} alt="add icon" />
                            <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.1px", color: "white", textTransform: "none" }} >Withdraw</Typography>
                        </Button></Tooltip>:
                            <Button disabled={withdrawDisabled } sx={{ width: "100%", padding: "8px 12px", height: "36px", bgcolor: "#3F47524D", '&:hover': { bgcolor: "#3F47524D" }, display: "flex", gap: "8px" }} onClick={()=>{dispatch(updateOpenWithdrawRequest(true));dispatch(updateWithdraw(true))}}>
                                <img src={moneyWithdraw} alt="add icon" />
                                <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.1px", color: "white", textTransform: "none" }} >Withdraw</Typography>
                            </Button>
                             }
                            <Divider orientation='vertical' sx={{ border: "1px solid transparent" }} />
                            <Button sx={{ width: "100%", padding: "8px 12px", height: "36px", bgcolor: "#3F47524D", '&:hover': { bgcolor: "#3F47524D" }, display: "flex", gap: "8px" }} onClick={() => { setTransferType("self");setIsMETransferClicked(true);setIsMCTransferClicked(false); setTransferModalOpen(true);setTransfer(true) }}>
                                <img src={compare} alt="add icon" />
                                <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.1px", color: "white", textTransform: "none" }}>Transfer</Typography>
                            </Button>
                        </Box></>}
                    </Box>

                </Box>
                <TransactionsAndWithdrawals/>
                <Modal open={transferModalOpen} onClose={() => handlecloseModal() } sx={{ height: "100%", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', border: "none", display: userbalmodal?"none":"flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%" }}>
                {!userreponse?.account?.transaction_pin?(<Box>
                        <Redirection
                            headings="Transaction pin"
                            navigation="/profile#transaction_pin"
                            content="For your security, you need to set up a Transaction PIN to proceed with transferring funds. Please create your Transaction PIN now to ensure your transaction is protected."
                            button="Setup transaction pin"
                        />
                    </Box> )
                   :<Box sx={{ bgcolor: "white", padding: "24px", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", maxWidth: "613px", width: "100%" }}>
                        <Typography sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "primary.light" }}>Transfer</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={radioValue}
                                        onChange={handleChangeRadio}
                                    >
                                        <FormControlLabel disabled={transferType=="self"?true:false} value="To others" control={<Radio />} label="To others" />
                                        <FormControlLabel disabled={transferType=="To others"?true:false} value="To self" control={<Radio />} label="To self" />

                                    </RadioGroup>
                                </FormControl>
                                <Divider />
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", justifyContent: "space-between" }}>
                                {radioValue == 'To others' && <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", height: "140px" }}>
                                    <Typography sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "black" }}>Enter profile Name</Typography>
                                    <Box sx={{ width: "100%", display: "flex", gap: "24px", position: "relative", flexDirection: { xs: "column", md: "row" } }}>
                                        <InputBase sx={{ maxWidth: "481px", width: "100%", height: "36px", borderRadius: "4px", gap: "8px", border: "1px solid #CBD5E1", padding: "8px 12px 8px 40px" }} value={searchedUser} placeholder="Enter username" onChange={(e) => setSearchUser(e.target.value)} onKeyDown={handleEnter} onBlur={handleGetuser} />
                                        <SearchIcon sx={{ position: "absolute", left: "12px", top: "8px", color: "#CBD5E1" }} />
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", gap: "12px", height: "140px" }}>
                                    {usersSearched?.message=="success" &&<FormControl sx={{ height: "64px", display: "flex", flexDirection: "row", gap: "12px",marginRight:"0px" }}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={selecteIdForTransaction}
                                            onChange={handleChangeSelf}

                                        >
                                            <FormControlLabel
                                                value={usersSearched?.user?.id}
                                                control={<Radio />}
                                                label={usersSearched?.user?.lastname}
                                                sx={{marginRight:0}}
                                            />
                                        </RadioGroup>
                                        <Box sx={{ display: "flex",  gap: "16px", height: "64px",alignItems:"center" }}>
                                            {imagestatus ? <Box component="img" sx={{height:"48px",width:"48px",objectFit:"cover"}} src={usersSearched?.attachments?.original} onError={(e) => { setImageStatus(false) }} ></Box> :
                                                <Box sx={{height:"48px",width:"48px",background:"radial-gradient(96.67% 96.67% at 47.15% 9.39%, #B01F8D 0%, #F9B938 100%)",color:"#FFF",display:"flex",alignItems:"center",justifyContent:"center"}}>{usersSearched?.user?.first_name.split("")[0].toUpperCase() + " " + userreponse?.user?.last_name.split("")[0].toUpperCase()}</Box>
                                            }
                                            <Box sx={{display:"felx",flexDirection:"column",gap:"2px"}}>
                                                    <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.25px", color: "black"}}>{capitalize(usersSearched?.user?.first_name) + " " +capitalize(usersSearched?.user?.last_name) }</Typography>
                                                    <Typography sx={{ fontWeight: "400", fontSize: "12px", lineHeight: "16px", letterSpacing: "0.4px", color: "grey"}}>Member ID: {usersSearched?.user?.mid}</Typography>
                                                    {/* <Typography sx={{ fontWeight: "400", fontSize: "12px", lineHeight: "16px", letterSpacing: "0.4px", color: "grey"}}>Account Number: XXXX XXXX XXXX XXXX</Typography> */}
                                            </Box>
                                        </Box>
                                    </FormControl>}
                                </Box>
                                </Box>}
                                {radioValue == 'To self' && <Box sx={{ display: "flex", flexDirection: "row", gap: "12px", height: "140px" }}>
                                    <FormControl sx={{ height: "64px", display: "flex", flexDirection: "row", gap: "12px",marginRight:"0px" }}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={selecteIdForTransaction}
                                            onChange={handleChangeSelf}
                                        >
                                            <FormControlLabel
                                                value={userreponse?.user?.id}
                                                control={<Radio />}
                                                label={userreponse?.user?.lastname}
                                            />
                                        </RadioGroup>
                                        <Box sx={{ display: "flex",  gap: "16px", height: "64px",alignItems:"center" }}>
                                            {imagestatus ? <Box component="img" sx={{height:"48px",width:"48px",objectFit:"cover"}} src={userreponse.att?.original} onError={(e) => { setImageStatus(false) }} ></Box> :
                                                <Box sx={{height:"48px",width:"48px",background:"radial-gradient(96.67% 96.67% at 47.15% 9.39%, #B01F8D 0%, #F9B938 100%)",color:"#FFF",display:"flex",alignItems:"center",justifyContent:"center"}}>{userreponse?.user?.first_name.split("")[0].toUpperCase() + " " + userreponse?.user?.last_name.split("")[0].toUpperCase()}</Box>
                                            }
                                            <Box sx={{display:"felx",flexDirection:"column",gap:"2px"}}>
                                                    <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", letterSpacing: "0.25px", color: "black"}}>{capitalize(userreponse?.user?.first_name) + " " +capitalize(userreponse?.user?.last_name) }</Typography>
                                                    <Typography sx={{ fontWeight: "400", fontSize: "12px", lineHeight: "16px", letterSpacing: "0.4px", color: "grey"}}>Member ID: {userreponse?.user?.mid}</Typography>
                                                    {/* <Typography sx={{ fontWeight: "400", fontSize: "12px", lineHeight: "16px", letterSpacing: "0.4px", color: "grey"}}>Account Number: XXXX XXXX XXXX XXXX</Typography> */}
                                            </Box>
                                        </Box>
                                    </FormControl>
                                </Box>}

                                <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                                    <Divider />
                                    <Typography sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "black" }}>Amount</Typography>
                                    <Box sx={{ width: "100%", display: "flex", gap: "10px", position: "relative", flexDirection:"column"}}>
                                        <InputBase sx={{ maxWidth: "481px", width: "100%", height: "36px", borderRadius: "4px", gap: "8px", border: "1px solid #CBD5E1", padding: "8px 12px " }} onChange={handleTransferAmount} placeholder="Enter the amount" />
                                        {validations.internalTransferAmount!=="" && <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "11px", color: "error.light" }}>{validations.internalTransferAmount}</Typography>}
                                    </Box>
                                </Box>

                            </Box>
                            <Button variant="contained" sx={{ width: "100%", maxWidth: "565px", height: "48px", textTransform: "none" }} disabled={Object.keys(validations).length==0 && ((searchedUser && Transferamt>0 && selecteIdForTransaction!=="") || (Transferamt>0 && isMETransferClicked && selecteIdForTransaction!=="")) ? false : true} onClick={() => {handleTransfer();
                                isMCTransferClicked ? setMcMe("mc") : setMcMe("me")}}><Typography>{isloading? <CircularProgress color="inherit" size={30}/> : 'Transfer'}</Typography></Button>
                        </Box>
                    </Box>}

                </Modal>
                <WithdrawForm/>
                <Modal open={addMoneyModalOpen} onClose={() => handlecloseAddmoneyModal() } sx={{ height: "100%", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', border: "none", display: userbalmodal?"none":"flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%" }}>
                {(userreponse?.user?.mobileno=="" || userreponse?.user?.mobileno==null) ?(<Box>
                    <Redirection
                        headings="Update Mobile Number"
                        navigation="mobileno"
                        content="To receive your OTP securely, please update your mobile number in your profile.Click the button below to update now."
                        button="Update Mobile Number"
                        />
                    </Box> )
                   :<Box sx={{ bgcolor: "white", padding: "24px", display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", maxWidth: "400px", width: "100%" }}>
                        <Typography sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "primary.light" }}>Add Money to Mayi Cash</Typography>
                       <Box sx={{display:"flex",flexDirection:"column",gap:"8px",width:"100%"}}>
                            <Typography sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.15px", color: "black" }}>Amount</Typography>
                            <InputBase sx={{width: "100%", height: "36px", borderRadius: "4px", gap: "8px", border: "1px solid #CBD5E1", padding: "8px 12px 8px 12px" }} value={amountTobeAdded} placeholder="Enter the amount" onChange={(e) => setAmountTobeAdded(e.target.value)} onKeyDown={handleEnterAmount} />
                            {/* <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16px", letterSpacing: "0.15px", color: "black",bgcolor:"grey.200" }}><Typography sx={{ fontWeight: "700", fontSize: "11px", lineHeight: "16px", letterSpacing: "0.15px", color: "black",display:"inline-block" }}>Note:</Typography> A minimum of ₹50 will be charged for every transaction</Typography> */}
                            <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16px", letterSpacing: "0.15px", color: "black",bgcolor:"grey.200" }}><Typography sx={{ fontWeight: "700", fontSize: "11px", lineHeight: "16px", letterSpacing: "0.15px", color: "black",display:"inline-block" }}>Note:</Typography> Transaction fee of 2.31% will be applied to the total value.</Typography>
                       </Box>
                            <Button variant="contained" disabled={amountTobeAdded>0?false:true} sx={{height:"48px",width:"100%",borderRadius:"4px",padding:"12px",textTransform:"none"}} onClick={()=>handlePayment()}>{amountTobeAdded>0?"Add" + " ₹ " + parseFloat(amountTobeAdded * 1.0231).toFixed(2).toString():"Continue"}</Button>
                       </Box>}

                </Modal>
            </Box>
        </Box>
        <OTP_pop/>
    </Box>
    )
}