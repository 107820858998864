import { Box, Paper, Table, Typography, TableContainer, TableHead, TableRow, TableBody, TableCell, Divider,Stack,InputBase,Select,MenuItem,Button } from "@mui/material"
import Header1 from "../components/header1"
import AdminSidebar from "../components/adminSideBar"
import CustomAxios from "../utils/CustomAxios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateInventoryData } from "../redux/admin"
import { Link } from "react-router-dom"
import stockico from "../assets/Feature.svg"

import { Delete, DeleteOutline, Edit, EditNote, Filter, Search } from '@mui/icons-material';


const Stocklist = () => {

    const dispatch = useDispatch()
    const products = useSelector(state => state.admin.inventoryData)
  const productCategory = useSelector(state => state.product?.productCategory)
  console.log(products)
//   const products = useSelector(state => state.product.Allproducts)
  const [filteredProducts,setFilteredProducts] = useState(null)
  const [Filters,setFilters] = useState({
    search:"",
    status:"",
    category:""
  })
    useEffect(()=>{
        let isFiltered = false
        let data = ""
        console.log("??????????????????????????????????/filterred data",Filters,products)
        if(Filters.category && Filters.status){
          
            if (Filters.status=='Out Of Stock'){
                data = products?.filter((e)=>e.available_quantity <=0 && e.products.category.toLowerCase() === Filters.category)
            }
            else if (Filters.status=='Low Stock'){
                data = products?.filter((e)=>e.available_quantity <=25 && e.products.category.toLowerCase() === Filters.category) 
            }
            else if (Filters.status=='In Stock'){
                data = products?.filter((e)=>e.available_quantity > 25 && e.products.category.toLowerCase() === Filters.category)
            }
         
        //   data = products?.filter((e)=>e.available_quantity == Filters.status && e.category === Filters.category)
          isFiltered = true
        }
        else
         if(Filters.category){
          data = products?.filter((e)=>e.products.category === Filters.category)
          isFiltered = true
        }
        else if(Filters.status){
            if (Filters.status=='Out Of Stock'){
                data = products?.filter((e)=>e.available_quantity <=0 )
            }
            else if (Filters.status=='Low Stock'){
                data = products?.filter((e)=>e.available_quantity <=25)
            }
            else if (Filters.status=='In Stock'){
                data = products?.filter((e)=>e.available_quantity > 25)
            }
         
          
          isFiltered = true
        }
        else if(Filters.search?.trim()){
            // console.log("??????????????????????????products to lower case sensitive", products.filter((e)=>e.products?.name.toLowerCase().includes(Filters.search.toLowerCase())))
          data = products.filter((e)=>e.products?.name.toLowerCase().includes(Filters.search.toLowerCase()) || e.products.sku.toLowerCase().includes(Filters.search.toLowerCase()) )
        }
        else if( (!Filters.category && !Filters.status) || (!Filters.search && !Filters.category && !Filters.status) ){
          data = null
        }
        console.log(data)
        if(Filters.search?.trim() && isFiltered && filteredProducts?.length >=1 ){
          data = data.filter((e)=>e.products.name.toLowerCase().includes(Filters.search.toLowerCase()) || e.products.sku.toLowerCase().includes(Filters.search.toLowerCase()) )
        }
        setFilteredProducts(data)
    
      },[Filters])
    
      const handlePlaceholder = (val,placeholder)=>{
        if(!val){
          return <Typography sx={{color:"#64748B",fontSize:"14px",fontWeight:400}}>{placeholder}</Typography>
        }
        return val
      }
    const stockNotify = (quantity) => {
        if (quantity <= 0) {
            return <Box sx={{ display: "flex", alignItems: "center",width:"100%" }}>
              <Typography sx={{width:"50%"}}>{quantity}</Typography>
                <Box sx={{ padding: "8px", bgcolor: "#FF5449", borderRadius: "5px" }}>
                    <Typography sx={{ color: "#fff" }}> Out Of Stock</Typography>
                </Box>

            </Box>
        }
        else if (quantity <= 25) {
            return <Box sx={{ display: "flex", alignItems: "center",width:"100%" }}>
               <Typography sx={{width:"50%"}}>{quantity}</Typography>
                <Box sx={{padding: "8px", bgcolor: "#FACC15", borderRadius: "5px" }}>
                    <Typography sx={{ color: "#000" }}> Low Stock</Typography>
                </Box>

            </Box>
        }
        else {
            return <Box sx={{ display: "flex", alignItems: "center",width:"100%" }}>
                <Typography sx={{width:"50%"}}>{quantity}</Typography>
               
                    <Box sx={{ padding: "8px", bgcolor: "#00C561", borderRadius: "5px" }}>
                        <Typography sx={{ color: "#fff" }}> In Stock</Typography>
                    </Box>
                

            </Box>
        }

    }
    const totalStock = () => {
        let totalQty = 0
        for (let i = 0; i < products.length; i++) {
            // console.log(products[i]?.available_quantity)
            totalQty += parseInt(products[i]?.inventry_details.available_quantity)
        }
        return totalQty

    }
    const outOfStock = () => {
        let outOf_Stock = 0
        for (let i = 0; i < products.length; i++) {
            if (products[i]?.inventry_details.available_quantity <= 0) {
                outOf_Stock += 1
            }
        }
        return outOf_Stock
    }
    const lowStock = () => {
        let low_stock = 0
        for (let i = 0; i < products.length; i++) {
            if (products[i]?.inventry_details.available_quantity <= 25 &&products[i]?.inventry_details.available_quantity > 0 ) {
                low_stock += 1
            }
        }
        return low_stock
    }


   
    
    let expiredStock = () => {
        let expired_quantity = 0
        // products
        for (let i = 0; i < products.length; i++) {
            
                expired_quantity += products[i]?.inventry_details.expired_quantity
            
        }
        return expired_quantity
    }
    // console.log(products)
    return <>

        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
            <Header1 />
            <Box sx={{ display: "flex", paddingTop: "144px", gap: "24px", mx: "24px", position: "relative" }}>
                <AdminSidebar />
                <Box className="background" sx={{ width: { xs: "calc(100% )", sm: "100%" }, p: { xs: "0px", sm: "0px" } }}>
                    <Paper sx={{ padding: "24px" }}>
                        <Box sx={{display:"flex",flexDirection:"column",gap:"16px"}}>
                            <Typography sx={{ fontSize: "20px", lineHeight: "28px", fontWeight: "700" }}>Inventory Management</Typography>
                            <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                                <Box component={'img'} src={stockico} sx={{}} />
                                <Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: "400" }}>
                                    Stock List
                                </Typography>

                            </Box>
                        </Box>
                        <Box sx={{display:"flex",flexDirection:"column",gap:"20px"}}>
                            <Box sx={{  }}>
                                <Box sx={{ padding: "5px", display: "flex", justifyContent: "space-around", padding: "16px", borderRadius: "5px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                            {products.length}
                                        </Typography>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                            Total Product
                                        </Typography>
                                    </Box>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                            {/* {products.map(a=>parseInt(a.total_quantity)+parseInt(a.available_quantity))} */}
                                            {totalStock()}
                                        </Typography>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                            In Stock
                                        </Typography>

                                    </Box>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                            {lowStock()}
                                        </Typography>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                            Low Stock
                                        </Typography>

                                    </Box>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <Typography sx={{ fontSize: "24px", lineHeight: "32px", fontWeight: "500" }}>
                                            {outOfStock()}
                                        </Typography>
                                        <Typography sx={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400" }}>
                                            Out Of Stock
                                        </Typography>

                                    </Box>
                                    <Divider orientation="vertical"  variant="middle" flexItem  />
                                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                        <Typography sx={{fontSize:"24px",fontWeigt:"32px",fontWeight:"500"}}>
                                            {expiredStock()}
                                        </Typography>
                                        <Typography sx={{fontSize:"14px",fontWeigt:"20px",fontWeight:"400"}}>
                                            Expired
                                        </Typography>

                                    </Box>
                                </Box>

                            </Box>
                            <Box>

                                <Stack direction={'row'} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <Stack direction={'row'} gap={'20px'}>
                                        <InputBase inputProps={{ className: 'product-search' }} value={Filters.search} startAdornment={<Search sx={{ color: "#53AEFF" }} />} placeholder='Product name / SKU#' onChange={(e) => { setFilters({ ...Filters, search: e.target.value }) }} sx={{ p: "8px 12px", gap: "8px", border: "1px solid #CBD5E1", borderRadius: "4px", height: "36px", width: "298px" }} />
                                        <Select displayEmpty value={Filters.status} sx={{ gap: "8px", borderRadius: "8px", height: "36px", width: "140px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" } }} onChange={(e) => setFilters({ ...Filters, status: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Status')}>
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem value="In Stock">In Stock</MenuItem>
                                            <MenuItem value="Low Stock">Low Stock</MenuItem>
                                            <MenuItem value="Out Of Stock">Out Of Stock</MenuItem>
                                        </Select>
                                        <Select displayEmpty value={Filters.category} placeholder='Category' sx={{ gap: "8px", borderRadius: "8px", height: "36px", width: "140px", "& .MuiSelect-select": { display: "flex", alignItems: "flex-end" } }} onChange={(e) => setFilters({ ...Filters, category: e.target.value })} renderValue={(val) => handlePlaceholder(val, 'Category')} >
                                            <MenuItem value="">All</MenuItem>
                                            {
                                                productCategory?.map((type, type_id) => {
                                                    return (
                                                        <MenuItem key={type_id} value={type} sx={{ textTransform: "capitalize" }}>{type}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Stack>
                                    <Button sx={{ p: "8px 12px", textTransform:"none",borderRadius: "4px", "&:hover": { color: "#fff" } }} variant="contained" component={Link} to="/product/new" >+ Create Product</Button>
                                </Stack>
                            </Box>
                            <Box>
                                <TableContainer sx={{ bgcolor: "#fff" }}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead sx={{ background: "#E2E8F0" }}>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "600", textAlign: "left" }}>Product</TableCell>
                                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }}>SKU</TableCell>
                                                {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Category</TableCell> */}
                                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Total Quantity</TableCell>
                                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Quantity in Stock</TableCell>
                                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Sold Out Quantity</TableCell>
                                                <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Expired Quantity</TableCell>
                                                {/* <TableCell sx={{ fontWeight: "600", textAlign: "center" }} align="right">Date Added</TableCell> */}

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { (filteredProducts? filteredProducts: products).map((item) => <TableRow sx={{ textDecoration: "none", '&:hover': { textDecoration: "none" } }} component={Link} to={`/stocklist/${item.inventry_details.id}`}>
                                                <TableCell sx={{ textAlign: "left", textUnderlineOffset: "" }}><Box sx={{ display: "flex", gap: "8px", alignItems: "center", textTransform: "capitalize" }}>< Box component="img" src={item.cover_details?.original} sx={{ width: "50px" }} />{item.name}</Box></TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{item.sku}</TableCell>
                                                {/* <TableCell sx={{ textAlign: "center" }}>Liquid</TableCell> */}
                                                <TableCell sx={{ textAlign: "center" }}>{item.inventry_details.total_quantity}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{stockNotify(item.inventry_details.available_quantity)}</TableCell>
                                                <TableCell sx={{ textAlign: "center" }}>{item.inventry_details.soldout_quantity}</TableCell>
                                                <TableCell sx={{ textAlign:"center" }}>{item.inventry_details.expired_quantity}</TableCell>
                                            </TableRow>)}



                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>


                        </Box>

                    </Paper>
                </Box>

            </Box>




        </Box>

    </>

}

export default Stocklist