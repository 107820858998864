import { Paper, Box, Typography, Divider, Button, InputBase, Grid, Menu, MenuItem, Checkbox, FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import ayurvedic from "../assets/ayurvedic.svg"
import organic from "../assets/organic.svg"
import axios from "axios"
// import products from "../redux/products"
import placeholder from "../assets/placeholder.jpg"
import CustomAxios from "../utils/CustomAxios"
import { useDispatch, useSelector } from "react-redux"
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import badge from "../assets/badge.png"
import { updateAllProduct, updateCart } from "../redux/products"
import { CurrencyRupee } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { useLocation } from "react-router-dom"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { updateSnackBarMessage, updateSnackBarSeverity, updateSnackBarOpen } from "../redux/snackbar";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SearchIcon from '@mui/icons-material/Search';
export const styles = {
    heading: { fontSize: { xs: "24", sm: "24px", md: "32px" }, fontWeight: "700", lineHeight: "48px", color: "black" },
    subHeading: { fontSize: { xs: "16", sm: "16px", md: "20px" }, fontWeight: "400", lineHeight: "30px", color: "black" },
    productHeading: { fontSize: { md: "16px" }, fontWeight: "600", lineHeight: "24px", color: "black" },
    productContent: { fontSize: { md: "12px" }, fontWeight: "400", lineHeight: "18px", color: "black" },
    productSize: { fontSize: { md: "10px" }, fontWeight: "400", lineHeight: "15px", color: "grey.600" },

} 

const Product_list = () => {
    const location = useLocation()
    const cart = useSelector(state => state.product?.cart)
    const bg = useSelector(state => state.product?.bg)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [imageError, serImageError] = useState(true)
    const [searchText, setSearchText] = useState('');
    const [product, setProduct] = useState()
    const id = useSelector(state => state.user.id)
    const authtoken = localStorage.getItem("authtoken")
    const [anchorElAvailability, setAnchorElAvailability] = useState(null);
    const [anchorElForWhom, setAnchorElForWhom] = useState(null);
    const [anchorElCategory, setAnchorElCategory] = useState(null);
    const [checkState, setCheckState] = useState({
    });

    const handleChange = (event) => {
        setCheckState({
            ...checkState,
            [event.target.name]: event.target.checked,
        });
    };
    const openAvailability = Boolean(anchorElAvailability);
    const openForWhom = Boolean(anchorElForWhom);
    const openCategory = Boolean(anchorElCategory);

    useEffect(() => {
        // // console.log(product)
        getProducts()
    }, [])
    const getProducts = async () => {

        await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}products/`,).then(({ data }) => {
            // // console.log(data)
            const mergedArray = data?.map(detail => {
                const backgroundClr = bg.find(item => item.name === detail.name);
                return { ...detail, bgcolor: backgroundClr ? backgroundClr.color : null };
            });
            dispatch(updateAllProduct(mergedArray))
            setProduct(mergedArray)

        })
    }
    const updateQuantity = async (object, condition, event) => {
        // console.log(object)
        event.stopPropagation();
        let products = [...cart]
        const newCart = products.map(item => {
            if (condition && item.product?.id == object.id) {
                let updated = { ...item }
                updated.quantity += 1
                // // console.log("if condition")
                if (item.id) {
                    CustomAxios.patch(`cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                    })
                    return updated
                }
                else {

                    return updated

                }

            }
            else if (!condition && item.product?.id == object.id) {

                let updated = { ...item }
                updated.quantity -= 1

                if (item.id && updated.quantity >= 1) {
                    CustomAxios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice: updated.quantity * (parseFloat(updated.price) + Math.round(updated?.price * (updated.product?.tax / 100)))
                    })
                    return updated
                }
                else if (item.id && updated.quantity < 1) {
                    CustomAxios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`)
                    return updated
                }
                else {

                    return updated

                }


            }
            else {
                return item

            }

        })

        // console.log(newCart)
        // setProduct(products)
        dispatch(updateCart(newCart.filter((item) => !item.quantity < 1)))
        localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item.quantity < 1)))
        // console.log("clipboard open")
        dispatch(updateSnackBarMessage("Successfully Updated the Cart!"))
        dispatch(updateSnackBarSeverity("success"))

        dispatch(updateSnackBarOpen(true))

    }
    const cartCheck = (id) => {
        // // console.log(id)
        // // console.log(cart)
        const filt = cart.filter(item => item.product?.id == id)
        // filt[0].quantity
        if (filt.length > 0) {
            return filt[0].quantity

        }
        else {
            return false
        }

    }
    const productDetails = (id) => {
        // console.log("product id list", id)
        navigate(`/product_details/${id}`)
    }
    const addCart = (item, quantity, event) => {
        event.stopPropagation();
        if (item) {
            const addCart = {
                product: item,
                quantity: quantity,
                price: authtoken ? item.selling_price : item.price, // Add price or selling price based on auth token
                originalPrice: item.price // Store original price as well
            }
            dispatch(updateCart([...cart, addCart]))
            localStorage.setItem("cart", JSON.stringify([...cart, addCart]))
            if (authtoken) {
                handleCart(item, quantity)
            }
        }
        // console.log("clipboard open")
        dispatch(updateSnackBarMessage("Successfully Added to Cart!"))
        dispatch(updateSnackBarSeverity("success"))

        dispatch(updateSnackBarOpen(true))
    }
    const handleCart = async (item, quantity) => {
        console.log(item, quantity, id)
        console.log(quantity, item.price, item.price, item.product?.tax / 10)
        await CustomAxios.post(`cart/`, {
            products: item.id,
            quantity: quantity,
            user: id, created_by: id,
            price: item.selling_price,
            totalprice: quantity * (parseFloat(item.selling_price) + Math.round(item.selling_price * (item.tax / 100))),
            updated_by: id
        })
        const response = await CustomAxios.get(`usercart/${id}`,)
        // console.log(response)
        dispatch(updateCart(response.data))
        localStorage.setItem("cart", JSON.stringify(response.data))


    }
    // const isMobile = useMediaQuery({ maxWidth: 600 });
    const isTablet = useMediaQuery({ maxWidth: 900, minWidth: 0 });
    const checkedItems = Object.entries(checkState)
    .filter(([key, value]) => value)
    .map(([key]) => key);

    const [filtered, setFiltered] = useState([])
    useEffect(() => {
        const fill = product?.filter((item) => {
            const matchesType = checkedItems.length === 0 || checkedItems.some((checkedItem) => 
                item.category.toLowerCase().includes(checkedItem.toLowerCase())
            );
    
            const matchesName = searchText === '' || item.name.toLowerCase().includes(searchText.toLowerCase());
    
            return matchesType && matchesName;
        });
    
        if (JSON.stringify(fill) !== JSON.stringify(filtered)) {
            setFiltered(fill);
        }
    }, [product, checkedItems, searchText]);
    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: { xs: "20px", sm: "20px", md: "64px 120px" }, background: "white", gap: "30px"}}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={styles.heading}>Discover the Power of Ayurveda</Typography>
                <Typography sx={styles.subHeading}>Experience natural wellness with our Herbal Health Supplement.</Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
                <Box sx={{ width: "100%", display: "flex", gap: "24px",position:"relative",flexDirection:{xs:"column",md:"row"} }}>
                    <InputBase sx={{ width: {xs:"100%",md:"70%"}, height: "36px", borderRadius: "4px", gap: "8px", border: "1px solid grey", padding: "8px 12px 8px 40px" }} placeholder="Search products" onChange={(e) => setSearchText(e.target.value)} />
                    <SearchIcon sx={{position:"absolute",left:"12px",top:"8px",color:"grey"}}/>
                   <Box sx={{display:"flex",justifyContent:"space-between",width:"30%"}}>
                    {/* <Box>
                        <Button
                            id="basic-button"
                            aria-controls={openAvailability ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openAvailability ? 'true' : undefined}
                            onClick={(event) => setAnchorElAvailability(event.currentTarget)}
                            sx={{ color: "black", textTransform: "none" }}
                        >
                            Availability <KeyboardArrowDownIcon />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElAvailability}
                            open={openAvailability}
                            onClose={() => setAnchorElAvailability(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.instock}
                                            onChange={handleChange}
                                            name="In Stock"
                                            inputProps={{ 'aria-label': 'Instock' }}
                                        />
                                    }
                                    label="Instock"
                                />
                            </MenuItem>
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.ExcludeoutOfStock}
                                            onChange={handleChange}
                                            name="Exclude out Of Stock"
                                            inputProps={{ 'aria-label': 'Exclude out Of stock' }}
                                        />
                                    }
                                    label="Exclude out Of stock"
                                />
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        <Button
                            id="basic-button"
                            aria-controls={openForWhom ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openForWhom ? 'true' : undefined}
                            onClick={(event) => setAnchorElForWhom(event.currentTarget)}
                            sx={{ color: "black", textTransform: "none" }}
                        >
                            For Whom <KeyboardArrowDownIcon />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElForWhom}
                            open={openForWhom}
                            onClose={() => setAnchorElForWhom(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.Children}
                                            onChange={handleChange}
                                            name="Children"
                                            inputProps={{ 'aria-label': 'Children' }}
                                        />
                                    }
                                    label="Children"
                                />
                            </MenuItem>
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.Mens}
                                            onChange={handleChange}
                                            name="Mens"
                                            inputProps={{ 'aria-label': 'Mens' }}
                                        />
                                    }
                                    label="Mens"
                                />
                            </MenuItem>
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.Womens}
                                            onChange={handleChange}
                                            name="Womens"
                                            inputProps={{ 'aria-label': 'Womens' }}
                                        />
                                    }
                                    label="Womens"
                                />
                            </MenuItem>
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.Both}
                                            onChange={handleChange}
                                            name="Both"
                                            inputProps={{ 'aria-label': 'Both' }}
                                        />
                                    }
                                    label="Both"
                                />
                            </MenuItem>
                        </Menu>
                    </Box> */}
                    <Box>
                        <Button
                            id="basic-button"
                            aria-controls={openCategory ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openCategory ? 'true' : undefined}
                            onClick={(event) => setAnchorElCategory(event.currentTarget)}
                            sx={{ color: "black", textTransform: "none",fontSize:"14px",fontWeight:"400" }}
                        >
                            Category <KeyboardArrowDownIcon />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorElCategory}
                            open={openCategory}
                            onClose={() => setAnchorElCategory(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            
                        >
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.Syrup}
                                            onChange={handleChange}
                                            name="Syrup"
                                            inputProps={{ 'aria-label': 'Syrup' }}
                                            />
                                        }
                                        label={
                                            <span style={{ fontSize: "14px", fontWeight: 400 }}>
                                                Syrup
                                            </span>
                                        }
                                />
                            </MenuItem>
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.Capsule}
                                            onChange={handleChange}
                                            name="Capsule"
                                            inputProps={{ 'aria-label': 'Capsule' }}
                                            />
                                        }
                                        label={
                                            <span style={{ fontSize: "14px", fontWeight: 400 }}>
                                                Capsule
                                            </span>
                                        }
                                />
                            </MenuItem>
                            <MenuItem sx={{height:"40px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkState.Powder}
                                            onChange={handleChange}
                                            name="Powder"
                                            inputProps={{ 'aria-label': 'Powder' }}
                                            />
                                        }
                                        label={
                                            <span style={{ fontSize: "14px", fontWeight: 400 }}>
                                                Powder
                                            </span>
                                        }
                                />
                            </MenuItem>
                        </Menu>
                    </Box>
                    </Box>
                </Box>

                <Box sx={{display:"flex",gap:"24px"}}>
                {checkedItems.length>0&&checkedItems.map((key) => (
           <Box sx={{maxWidth:"200px",width:"100%",padding:"8px 12px",height:"40px",bgcolor:"grey.100",borderRadius:"8px",display:"flex",justifyContent:"center"}}><Typography sx={{fontSize:"14px",fontWeight:"400px"}}> {key}</Typography> <HighlightOffIcon sx={{cursor:"pointer",marginLeft:"10px"}} onClick={() => setCheckState(prevState => {
            const newState = { ...prevState };
            delete newState[key];
            return newState; })} /></Box>
        ))}
                   {checkedItems.length>0&&<Box sx={{maxWidth:"200px",width:"100%",padding:"8px 12px",height:"40px",bgcolor:"transparent",borderRadius:"8px",display:"flex",justifyContent:"center"}}><Typography sx={{fontSize:"14px",fontWeight:"400px",color:"error.light"}}> Remove All</Typography> <HighlightOffIcon sx={{cursor:"pointer",marginLeft:"10px"}} onClick={() => setCheckState({ })} /></Box>}
                </Box>
            </Box>
            <Grid container columns={12} spacing={4}>
                {filtered?.map((product) => (
                    product.status.toLowerCase() === "publish" && <Grid item xs={6} sm={4} md={3} key={product.id} style={{ alignSelf: "stretch", cursor: "pointer" }} onClick={() => productDetails(product.id)}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", gap: "24px" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", background: product.bgcolor }}>
                                    <Box component="img" sx={{ height: {xs:"100%",sm:"100%",md:"244px"}, width: "100%", maxWidth:{xs:"100%",sm:"100%",md:"282px"} }} src={imageError ? product.cover_details?.original : placeholder} />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <Typography sx={styles.productHeading}>{product.name}</Typography>
                                    <Typography sx={styles.productContent}>{product.details}</Typography>
                                    <Typography sx={styles.productSize}>Size: {product.unit}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                <Box sx={{ display: "flex", gap: "12px" }}>
                                    {/* <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", textDecoration: "line-through" }}><CurrencyRupee sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", marginTop: "-1px" }} />{(parseFloat(product.price) + Math.round(product?.price * (product?.tax / 100))).toFixed(2)}</Typography>
                                    <Divider orientation="vertical" flexItem />
                                    <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}><CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} />{(parseInt(product.selling_price) + Math.round(parseInt(product.selling_price) * (parseInt(product.tax) / 100))).toFixed(2)}</Typography> */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                            {authtoken ? <CurrencyRupee sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", marginTop: "-1px" }} />:<CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} />}
                                                {authtoken ? (
                                                <>
                                                   <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "error.light", textDecoration: "line-through" }}>
                                                   {(parseFloat(product.price) + Math.round(product?.price * (product?.tax/100) )).toFixed(2)}
                                                    </Typography>
                                                </>
                                                ) : 
                                                <>
                                               <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}>  {(parseFloat(product.price) + Math.round(product?.price * (product?.tax/100) )).toFixed(2)}</Typography>
                                                </>
                                                }
                                            </div>
                                            {authtoken && (
                                                <Box sx={{ display: "flex", gap: "12px",flexDirection:"row" }}>
                                                <Divider orientation="vertical" flexItem />
                                                <Box sx={{ display: "flex"}}>
                                                <CurrencyRupee sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6", marginTop: "-1px" }} />
                                                <Typography sx={{ fontSize: "18px", fontWeight: "600", lineHeight: "21.6px" }}>{ (parseInt(product.selling_price) + Math.round(parseInt(product.selling_price) * (parseInt(product.tax)/100))).toFixed(2) }</Typography>
                                                </Box>
                                                </Box>
                                            )}
                                            </Box>
                                {cartCheck(product.id) ? <Button onClick={(e) => addCart(null, null, e)} variant="contained" sx={{ width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" } ,display:"flex",gap:"20px"}}>
                                                    <Typography onClick={(e) => updateQuantity(product, false, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}>
                                                       <RemoveIcon sx={{fontSize:"15px",color:"#fff"}} />
                                                    </Typography>

                                                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                                        {cartCheck(product.id)}
                                                    </Typography>
                                                    <Typography onClick={(e) => updateQuantity(product, true, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}>
                                                        <AddIcon  sx={{fontSize:"15px",color:"#fff"}} />
                                                    </Typography>

                                                </Button> :
                                <Button variant="contained" sx={{ width: "100%", bgcolor: "black", height: "36px", padding: "8px 12px", textTransform: "none", "&:hover": { bgcolor: "black", color: "white" } }} onClick={(e) => addCart(product, 1, e)}><ShoppingCartOutlinedIcon sx={{ marginRight: "10px" }} /> Add to Cart </Button>}
                            </Box>
                        </Box>

                    </Grid>
                ))}
            </Grid>
        </Box >
    )


}

export default Product_list