import { Box, InputBase, Stack, Typography } from "@mui/material"
import AdminSidebar from "../components/adminSideBar"
import { Label } from "@mui/icons-material"
import Header from "../components/header"
import Deliverysettings from "./deliverysettings"
import Header1 from "../components/header1"

const Settings = ()=>{
    return(
        <Stack direction="row" sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
            <Header1/>
            <Stack direction="row" sx={{ display: "flex", paddingTop: "144px",gap:"24px",mx:"24px" , position:"relative",width:"100%"}}>
                <AdminSidebar/>
                <Deliverysettings/>
            </Stack>
        </Stack>
    )
}

export default Settings