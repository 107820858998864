import { Box,Paper,Button,Typography, TextField } from "@mui/material"
import { styles } from "./cards_old"
import { Link } from "react-router-dom"
import ProductList from "./productList"
import OrderList from "./orderList"
import { useState } from "react"
const OrderComponent=()=>{
    const [texttoFilter, setTexttoFilter] = useState("")

return (
    <Box sx={{  width: "100%",display:"flex",flexDirection:"column", gap:"32px" }} >

                <Paper sx={{ width: "100%", bgcolor: "#fff", padding: "32px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={styles.header}>Orders</Typography>
                    <TextField placeholder="Search Order" sx={{maxWidth:"500px",width:"100%",outline:"none"}} onChange={(e)=>setTexttoFilter(e.target.value)}/>
                    {/* <Button style={styles.Button} variant="contained" component={Link} to="/product/1" >+ Add Products</Button> */}
                </Paper>
                <OrderList texttoFilter={texttoFilter}/>

            </Box>
)
}

export default OrderComponent