
import { Box, Divider, Typography, Paper, InputBase, Button, RadioGroup, FormControlLabel, Radio, Chip, Stack, Grid, Skeleton, Select, MenuItem, Breadcrumbs, Link, Avatar, } from "@mui/material"
import { formStyle } from "../modules/profile";
import { Editor } from "primereact/editor";
// import { styles } from "./cards"
import { useEffect, useRef, useState } from "react";
import BackupIcon from '@mui/icons-material/Backup';
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { updateProduct } from "../redux/products";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import { json, useNavigate } from "react-router-dom";
import { Add, Close } from "@mui/icons-material";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import CircularProgress from '@mui/material/CircularProgress';

export const Styles = {
    header: { fontSize: "18px",lineHeight:"28px",fontWeight:500 },
    subheader: { fontSize: "14px", color: "#000000",fontWeight:600, lineHeight:"20px" }
}



const ProductUpdateForm = () => {
    const urlId = window.location.pathname.split('/')[2]
    // console.log("----------urlid--------",urlId)
    const product = useSelector(state => state.product?.product)
    const all_product = useSelector(state => state.product?.Allproducts)
    const [isloading, setIsloading] = useState(false)
    const [spefic_prod,setSpecific_prod] = useState('')
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({})
    const [newlyUploadedFiles,setNewlyUploadedFiles] = useState([])
    const [newlyUploadedCoverFiles,setNewlyUploadedCoverFiles] = useState("")
    const fileRef = useRef()
    const GfileRef = useRef()
    const navigate = useNavigate()
    const productCategory = useSelector(state => state.product?.productCategory)
    const EditorQuillRef = useRef()

    useEffect(()=>{
        if(isNaN(urlId) === false){
            let spefic_prod = all_product.filter(item=>item.id === parseInt(urlId))[0]
            setSpecific_prod(spefic_prod)
            dispatch(updateProduct({ ...spefic_prod,
                selling_price:parseFloat(spefic_prod.selling_price).toFixed(2),
                price:parseFloat(spefic_prod.price).toFixed(2),
                up_gallery:spefic_prod?.gallery_details?.original && JSON.parse(spefic_prod.gallery_details.original),
                //  cover:spefic_prod.cover_details?.original,
                //  gallery:spefic_prod.gallery_details?.original?JSON.parse(spefic_prod.gallery_details?.original):[],
                //  name:spefic_prod.name,
                //  description:spefic_prod.description,
                //  unit:spefic_prod.unit,
                //  tax:spefic_prod.tax,
                //  sku:spefic_prod.sku,
                //  status:spefic_prod.status,
                //  slugname:spefic_prod.slug,
                //  sellingPrice:spefic_prod.selling_price,
                //  quantity:spefic_prod.available_quantity,
                //  price:spefic_prod.price,
                //  type:product.status
                 
                }))
                // // console.log(spefic_prod?.cover_details?.original.split(`products-media/${spefic_prod.created_by}/${spefic_prod.name}/`))
                // setNewlyUploadedCoverFiles(spefic_prod?.cover_details?.original?.split(`products-media/${spefic_prod.created_by}/${spefic_prod.slug}/cover/`)[1])
                setNewlyUploadedCoverFiles(spefic_prod?.cover_details?.original)
            // console.log("-----calling api call for update------------",product)
            // axios.get(process.env.REACT_APP_BACKEND_BASE_URL+"products/"+urlId)
        }
    },[urlId])


    const validateProduct = (product) => {
        const newError = {}
        let valid = true

        // // console.log(product)
        if (!product.name?.trim()) {
            // console.log("it s happening")
            newError.name = "Please enter product name here"
            valid = false

        }

        if (!product.unit?.trim()) {
            // console.log("it s happening")
            newError.unit = "Please enter unit here"
            valid = false

        }
        if (!product.price) {
            // console.log("it s happening")
            newError.price = "Please enter price here"
            valid = false

        }
        if (!product.selling_price) {
            // console.log("it s happening")
            newError.sellingPrice = "Please enter selling price here"
            valid = false

        }
        if(parseInt(product.selling_price) > parseInt(product.price)){
            newError.sellingPrice = "Selling price must be less than price"
            valid = false
        }
        // if (!product.available_quantity) {
        //     // console.log("it s happening")
        //     newError.quantity = "Please enter quantity here"
        //     valid = false

        // }
        if (!product.sku?.trim()){
            // console.log("it s happening")
            newError.sku="Please enter SKU here"
            valid = false
        }
        if (!EditorQuillRef.current?.getQuill()?.root?.innerText?.trim()){
            // console.log("it s happening")
            newError.description="Please enter description here"
            valid = false
        }
        if(!product.details?.trim()){
            newError.details="Please enter brief here"
            valid = false
        }

        // }
        if (!product.tax?.trim()) {
            // console.log("it s happening")
            newError.tax = "Please enter tax here"
            valid = false

        }
        if (!product.status?.trim()) {
            // console.log("it s happening")
            newError.status = "Please enter status here"
            valid = false

        }

        if(!product['length'] || isNaN(parseFloat(product['length']))){
            newError.length = "Please enter a valid length"
            valid = false
        }
        if(!product.width || isNaN(parseFloat(product.width))){
            newError.width = "Please enter a valid width"
            valid = false
        }
        if(!product.height || isNaN(parseFloat(product.height))){
            newError.height = "Please enter a valid height"
            valid = false
        }

        if(!product.category){
            newError.category = "Please select product category."
            valid = false   
        }
        
        if(!newlyUploadedCoverFiles){
            newError.cover = "Please select a thumbnail image."
            valid = false
        }
        let gallery_img = product?.gallery && product?.gallery?.length>=1 ? [...product.up_gallery,...product.gallery] : product.up_gallery
        if( !gallery_img || (gallery_img && gallery_img.length<1)){
            newError.gallery = "Please select a gallery image."
            valid = false
        }

        // console.log(newError,product)
        setErrors(newError)

        return valid



    }



    const handleUpdateProduct = (status)=>{
        // console.log(product,spefic_prod)
        let dummy_details = {...product}
        let product_keys = Object.keys(product)
        let spefic_prod_keys =Object.keys(spefic_prod).filter(e=>product_keys.includes(e)) 
        // console.log(product_keys,spefic_prod_keys)
        let api_data = new FormData()
        api_data.append('user_id',spefic_prod.created_by)
        product?.status !== status && api_data.append('status',status)
        product_keys.map((key)=>{
            if(spefic_prod[key]!=product[key]){
                if(key === "cover"){
                    api_data.append("file",product[key])
                }
                else if(key === "gallery"){
                    for(let i=0;i<product[key].length;i++){
                        api_data.append("files",product[key][i])
                    }
                }
                else if(key === "up_gallery" && spefic_prod.gallery_details.original){
                    let urls=[]
                    JSON.parse(spefic_prod.gallery_details.original).map((e)=>{
                                                if(!product.up_gallery.includes(e)){
                            urls.push(e)
                        }
                    })
                    if(urls.length >=1){

                        api_data.append("delete_gallery_urls",JSON.stringify(urls))
                    }
                }
                else{
                    api_data.append(key,product[key])
                }
            }
        })
        api_data.forEach((val,key)=>{
            // console.log(key,"--->",val)
        })
        if(validateProduct(product)){
            setIsloading(true)
            CustomAxios.put('products/'+spefic_prod.id+"/",api_data).then((res)=>{
                setIsloading(false)
                let data = res.data
                if(data.id){
                    navigate('/product')
                    dispatch(updateProduct({}))
                    dispatch(updateSnackBarMessage("Updated Successfully"))
                    dispatch(updateSnackBarSeverity("success"))
                }
                else if(data.error){
                    console.error(data.error)
                    dispatch(updateSnackBarMessage("Failed"))
                    dispatch(updateSnackBarSeverity("error"))
                }
            }).catch(err=>{
                setIsloading(false)
                console.error(err)
                dispatch(updateSnackBarMessage("Failed"))
                dispatch(updateSnackBarSeverity("error"))
            })
            dispatch(updateSnackBarOpen(true))

        }
        else{
            // console.log("validation failed----------")
        }
    }

    const handleUpdateGallleryImgs = (url,index)=>{
        // console.log("handleUpdateGallleryImgs---------",url,index)
        if(url){

            let dummy_gallery = [...product.up_gallery]
            // console.log(dummy_gallery)
            // delete dummy_gallery[index]
            dummy_gallery.splice(index,1)
            // console.log(dummy_gallery)
            dispatch(updateProduct({...product,up_gallery:dummy_gallery}))
        }
        else{
            // let dummy_details = [...newlyUploadedFiles]
            // delete dummy_details[index]
            // setNewlyUploadedFiles(dummy_details)
            let dummy_details = [...product.gallery]
            dummy_details.splice(index,1)
            dispatch(updateProduct({...product,gallery:dummy_details}))
        }
    }

    const handleFileCheck = (file,files)=>{
        // // console.log("file check",file)
        if(file && file.type !== "" && file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'){
            // // console.log("------coming-------")
            return true
        }
        if(files){
            // // console.log(files)
            let values = Array.from(files).filter((file)=> file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg')
            return values.length>=1?false:true
        }

    }


    const handleGalleryUpload = (e)=>{
        // console.log("---------coming for gallery upload---------")
        e.preventDefault()
        let files = Array.from(e.target.files)
        // console.log(files)
        let dummy_gallery = []
        if(files.length>=1 && handleFileCheck(false,files) ){
            let comb_files = files.filter(file=>{
                if( !newlyUploadedFiles.includes(file.name) ){
                    return file
                }
            })
            // console.log("---------comb files------",newlyUploadedFiles,comb_files)
            product?.gallery?.length>=1 ? dispatch(updateProduct({ ...product, gallery: [...product.gallery,...comb_files] })) : dispatch(updateProduct({ ...product, gallery: comb_files }))
            for (let i = 0; i < files.length; i++) {
                dummy_gallery.push(files[i].name)
            }
            setNewlyUploadedFiles([...newlyUploadedFiles,...dummy_gallery])
        }
    }

    const handleCoverUpdate = (e)=>{
        e.preventDefault()
        let up_file = e.target.files[0]
        // let up_file = file
        // console.log("-------handleCoverUpdate------",up_file)
        if(up_file && handleFileCheck(up_file,false)){
            // console.log("----------update up cover------",up_file.name)
            let dummy_details = {...product}
            dummy_details['up_cover'] = ""
            // console.log("----------------",dummy_details)
            dispatch(updateProduct({...dummy_details}))
            setNewlyUploadedCoverFiles(up_file)
            // console.log("-------updated setNewlyUploadedCoverFiles--------------")
            dispatch(updateProduct({ ...product, cover: up_file }))
        }
    }
    
    const handleCoverDelete = (e,up_cover)=>{
        e.preventDefault()
        if(up_cover){
            let dummy_details = {...product}
            delete dummy_details['up_cover']
            // console.log("----------------")
            dispatch(updateProduct({...dummy_details}))
        }
        else{
            setNewlyUploadedCoverFiles("")
        }

    }

    const loopCombinedfiles = ()=>{
        // console.log("--------continous calling")
        if(product.up_gallery && product?.gallery?.length>=1 ){
            // console.log([...product?.up_gallery,...product?.gallery])
            return [...product?.up_gallery,...product?.gallery]
        }
        
        if(product.up_gallery){
            return product.up_gallery
        }
    }

    return (<Box sx={{ padding: "48px 32px ", width: "100%", display: "flex", gap: "32px", flexDirection: "column", height: "calc(100vh - 180px)", overflowY: "scroll", bgcolor:"#FFF" }}>
        <Stack gap={"16px"}>
            <Typography sx={Styles.header}>
                Inventory Management
            </Typography>
            {/* <Divider  sx={{marginTop:"32px",border:"1px dashed grey"}}/> */}
            <Breadcrumbs separator=">" sx={{pr:"8px"}}>
                <Link underline="none" href="/product" color="inherit" sx={{fontSize:"14px",fontWeight:400,lineHeight:"20px"}}>Products</Link>
                <Typography sx={{color:"#1099F4",fontWeight:700,fontSize:"14px",lineHeight:"20px"}}>Edit Product</Typography>
            </Breadcrumbs>
        </Stack>

        <Stack className="main-section" gap={"36px"}>
            <Stack className="sec1" gap="7px">
                <Typography sx={[Styles.subheader, { alignSelf: "start" }]}> Product Details </Typography>
                <Stack direction={{xs:"column",md:"row"}} sx={{gap:{xs:"36px",lg:"52px"}}}>
                    <Stack sx={{width:{md:"50%"},gap:"20px"}}>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700" >Name <Typography component="span" color="error">*</Typography></Typography>
                            <InputBase sx={formStyle.inputBase} value={product.name} onChange={(e) => dispatch(updateProduct({ ...product, name: e.target.value }))} />
                            {errors.name && <Typography sx={{ color: "error.light" }}>{errors.name}</Typography>}
                        </Box>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Slug Name</Typography>
                            <InputBase sx={formStyle.inputBase} disabled value={product.slug} onChange={(e) => dispatch(updateProduct({ ...product, slug: e.target.value }))} />
                        </Box>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Unit / Capacity <Typography component="span" color="error">*</Typography></Typography>
                            <InputBase sx={formStyle.inputBase} value={product.unit} onChange={(e) => dispatch(updateProduct({ ...product, unit: e.target.value }))} />
                            {errors.unit && <Typography sx={{ color: "error.light" }}>{errors.unit}</Typography>}
                        </Box>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Category <Typography component="span" color="error">*</Typography></Typography>
                            <Select fullWidth value={product?.category || null} onChange={(e)=> dispatch(updateProduct({ ...product, category: e.target.value })) } sx={{color:formStyle.inputBase.color}} >
                                <MenuItem value={null}>Select product category</MenuItem>
                                {
                                    productCategory?.map((type,type_id)=>{
                                        return(
                                            <MenuItem key={type_id} value={type} sx={{textTransform:"capitalize"}}>{type}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {errors.category && <Typography sx={{ color: "error.light" }}>{errors.category}</Typography>}
                        </Box>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Brief <Typography component="span" color="error">*</Typography></Typography>

                            <InputBase multiline rows={2} sx={formStyle.textBox} value={product.details} onChange={(e) => dispatch(updateProduct({ ...product, details: e.target.value }))} />

                            {errors.details && <Typography sx={{color:"error.light"}}>{errors.details}</Typography>}
                        </Box>
                    </Stack>
                    <Stack sx={{width:{md:"50%"}}}>
                        <Box sx={{height:"100%"}}>
                            <Typography sx={formStyle.label} color="grey.700">Description <Typography component="span" color="error">*</Typography></Typography>  

                            <Editor ref={EditorQuillRef} style={{...formStyle.textBox,borderColor:"#CBD5E1",height:"437px",width:"100%",borderTop:"none",borderTopRightRadius:0,borderTopLeftRadius:0}}  value={product.description} 
                            onTextChange={(e) =>{
                                dispatch(updateProduct({ ...product, description: e.htmlValue }))
                            }} 
                            />

                            {errors.description && <Typography sx={{color:"error.light"}}>{errors.description}</Typography>}
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack className="sec2" gap={"7px"}>
                <Typography sx={[Styles.subheader, { alignSelf: "start" }]}> Product Specification & Pricing </Typography>
                <Stack direction={{xs:"column",md:"row"}} sx={{gap:{xs:"36px",lg:"52px"}}}>
                    <Stack sx={{width:{md:"50%"},gap:"20px"}}>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">SKU <Typography component="span" color="error">*</Typography></Typography>
                            <InputBase sx={formStyle.inputBase} value={product.sku} onChange={(e) => dispatch(updateProduct({ ...product, sku: e.target.value }))} />
                            {errors.sku && <Typography sx={{color:"error.light"}}>{errors.sku}</Typography>}
                        </Box>
                        <Stack direction={{xs:"column",md:"row"}} gap={"24px"} sx={{justifyContent:"space-between",alignItems:"center"}}>
                            <Box sx={{width:"100%"}}>
                                <Typography sx={formStyle.label} color="grey.700">Price <Typography component="span" color="error">*</Typography></Typography>
                                <InputBase sx={formStyle.inputBase} value={product.price} onChange={(e) => dispatch(updateProduct({ ...product, price: e.target.value.replace(/\D/g, '') }))} />
                                {errors.price && <Typography sx={{ color: "error.light" }}>{errors.price}</Typography>}
                            </Box>
                            <Box  sx={{width:"100%"}}>
                                <Typography sx={formStyle.label} color="grey.700">Sale Price <Typography component="span" color="error">*</Typography></Typography>
                                <InputBase sx={formStyle.inputBase} value={product.selling_price} onChange={(e) => dispatch(updateProduct({ ...product, selling_price: e.target.value.replace(/\D/g, '') }))} />
                                {errors.sellingPrice && <Typography sx={{ color: "error.light" }}>{errors.sellingPrice}</Typography>}
                            </Box>
                        </Stack>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Tax <Typography component="span" color="error">*</Typography></Typography>
                            <InputBase sx={formStyle.inputBase} value={product.tax} onChange={(e) => dispatch(updateProduct({ ...product, tax: e.target.value.replace(/\D/g, '') }))} endAdornment={<Typography sx={{color:"grey.500"}}>%</Typography>}/>
                            {errors.tax && <Typography sx={{ color: "error.light" }}>{errors.tax}</Typography>}
                        </Box>

                    </Stack>
                    <Stack sx={{width:{md:"50%"},gap:"20px"}}>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Width <Typography component="span" color="error">*</Typography></Typography>
                            <InputBase required sx={formStyle.inputBase} value={product.width} onChange={(e) => dispatch(updateProduct({ ...product, width: e.target.value.replace(/\D/g, '') }))} endAdornment={<Typography sx={{color:"grey.500"}}>cm</Typography>} />
                            {errors.width && <Typography sx={{color:"error.light"}}>{errors.width}</Typography>}
                        </Box>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Height <Typography component="span" color="error">*</Typography></Typography>
                            <InputBase required sx={formStyle.inputBase} value={product.height} onChange={(e) => dispatch(updateProduct({ ...product, height: e.target.value.replace(/\D/g, '') }))} endAdornment={<Typography sx={{color:"grey.500"}}>cm</Typography>} />
                            {errors.height && <Typography sx={{color:"error.light"}}>{errors.height}</Typography>}
                        </Box>
                        <Box>
                            <Typography sx={formStyle.label} color="grey.700">Length <Typography component="span" color="error">*</Typography></Typography>
                            <InputBase required sx={formStyle.inputBase} value={product.length} onChange={(e) => dispatch(updateProduct({ ...product, length: e.target.value.replace(/\D/g, '') }))} endAdornment={<Typography sx={{color:"grey.500"}}>cm</Typography>} />
                            {errors.length && <Typography sx={{color:"error.light"}}>{errors.length}</Typography>}
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack className="sec3" gap={"36px"}>
                <Stack gap={"7px"}>
                    <Typography sx={[Styles.subheader, { alignSelf: "start" }]}> Cover/Thumbnail </Typography>
                    <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                    {
                        newlyUploadedCoverFiles ? 
                            <Grid item >
                                {
                                    <Stack gap={"7px"}sx={{position:"relative","&:hover .edit-cover-overlay":{display:"flex"}}}>
                                        <img src={typeof(newlyUploadedCoverFiles) === "string"?newlyUploadedCoverFiles:URL.createObjectURL(newlyUploadedCoverFiles)} style={{width:"160px",height:"150px",padding:"8px",border:"1px solid #DBE3F0",borderRadius:"10px"}}/>
                                        <Avatar className="edit-cover-overlay" sx={{display:"none",position:"absolute",top:0,right:0,cursor:"pointer",width:24,height:24,"&:hover":{bgcolor:"#1099F4"}}}><Close  color="#DBE3F0" sx={{fontSize:"14px"}} onClick={(e)=> handleCoverDelete(e,false) }  /></Avatar>
                                    </Stack>
                                    
                                }
                            </Grid>
                            :
                            <Stack onClick={() => { fileRef.current && fileRef.current.click() }} sx={{width:"160px",height:"150px",justifyContent:"center",alignItems:"center",border: "1px solid #D6DAE2",bgcolor:"#EDF1F9",cursor:"pointer",borderRadius:"10px"}}>
                                <Add></Add>
                                <input ref={fileRef} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {handleCoverUpdate(e);  }} style={{ display: "none" }} />
                            </Stack>
                    }
                    </Grid>
                    {errors.cover && <Typography sx={{ color: "error.light" }}>{errors.cover}</Typography>}

                </Stack>
                <Stack gap={"7px"}>
                    <Typography sx={[Styles.subheader, { alignSelf: "start" }]}> Gallery </Typography>
                    <Grid container direction="row" sx={{ gap: "1rem", pt: "20px" }}>
                        <Stack onClick={() => { GfileRef.current && GfileRef.current.click() }} sx={{width:"160px",height:"150px",justifyContent:"center",alignItems:"center",border: "1px solid #D6DAE2",bgcolor:"#EDF1F9",cursor:"pointer",borderRadius:"10px"}}>
                            <Add></Add>
                            <input ref={GfileRef} multiple={true} accept=".png, .jpg, .jpeg" type="file" onChange={(e) => { handleGalleryUpload(e,null);}} style={{ display: "none" }} />
                        </Stack>
                    {
                        product?.up_gallery?.length>=1 && product.up_gallery.map((file,f_id)=>{
                            return(
                                <Grid item key={f_id}>
                                    {
                                        <Stack gap={"7px"} sx={{position:"relative","&:hover .edit-gallery-overlay":{display:"flex"}}}>
                                            <img src={file} style={{width:"160px",height:"150px",padding:"8px",border:"1px solid #DBE3F0",borderRadius:"10px"}}/>
                                            <Avatar className="edit-gallery-overlay" sx={{display:"none",position:"absolute",top:0,right:0,cursor:"pointer",width:24,height:24,"&:hover":{bgcolor:"#1099F4"}}}><Close  color="#DBE3F0" sx={{fontSize:"14px"}} onClick={(e)=> handleUpdateGallleryImgs(true,f_id) }  /></Avatar>
                                        </Stack>
                                        
                                    }
                                </Grid>
                            )
                        })
                    }
                    {
                        product?.gallery?.length>=1 && product.gallery.map((file,f_id)=>{
                            return(
                                <Grid item key={f_id}>
                                    {
                                        <Stack gap={"7px"} sx={{position:"relative","&:hover .edit-gallery-overlay":{display:"flex"}}}>
                                            <img src={URL.createObjectURL(file)} style={{width:"160px",height:"150px",padding:"8px",border:"1px solid #DBE3F0",borderRadius:"10px"}}/>
                                            <Avatar className="edit-gallery-overlay" sx={{display:"none",position:"absolute",top:0,right:0,cursor:"pointer",width:24,height:24,"&:hover":{bgcolor:"#1099F4"}}}><Close  color="#DBE3F0" sx={{fontSize:"14px"}} onClick={(e)=> handleUpdateGallleryImgs(false,f_id) }  /></Avatar>
                                        </Stack>
                                        
                                    }
                                </Grid>
                            )
                        })
                    }
                    </Grid>
                    {errors.gallery && <Typography sx={{ color: "error.light" }}>{errors.gallery}</Typography>}

                </Stack>
            </Stack>
        </Stack>

        <Box sx={{bgcolor:"#F8F9FF",p:"20px 24px 20px 24px"}}>
            {
                isloading?
                    <Stack direction={"row"} sx={{gap:"24px",justifyContent:"flex-end"}}>
                        <Skeleton variant="rounded" width={80} height={37}>
                            <Button variant="contained" sx={{textTransform:"capitalize"}}>Publish</Button>
                        </Skeleton>
                        <Skeleton variant="rounded" width={105} height={37}>
                            <Button variant="outlined" sx={{textTransform:"capitalize"}}>Save Draft</Button>
                        </Skeleton>
                    </Stack>
                :
                    <Stack direction={"row"} sx={{gap:"24px",justifyContent:"flex-end"}}>
                        <Button variant="contained" sx={{textTransform:"capitalize"}} onClick={()=>handleUpdateProduct('publish')}>Publish </Button>
                        <Button variant="outlined" sx={{textTransform:"capitalize"}}  onClick={()=>handleUpdateProduct('draft')}>Save Draft</Button>
                    </Stack>
            }
        </Box>

        {/*------------- old code--------------------*/}

            {/* <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Feature Image
                </Typography>

                <Paper sx={{ padding: "32px", width: "60%" }}>
                    <input ref={fileRef} type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {handleCoverUpdate(e);  }} style={{ display: "none" }} />
                    <Box
                        onClick={() => { fileRef.current && fileRef.current.click() }}
                        sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                        <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                        <Typography sx={{ color: "primary.dark" }} >Upload an Image</Typography>
                        <Typography sx={{ color: "grey.800" }} >PNG,JPG</Typography>

                    </Box>
                    <Grid container sx={{gap:"1rem",pt:"20px"}}>
                        {newlyUploadedCoverFiles &&
                        <Grid item>

                            <Chip label={newlyUploadedCoverFiles} deleteIcon={<Close/>} onDelete={(e)=>{handleCoverDelete(e,false)}}/>
                        </Grid>
                        }
                    </Grid>
                </Paper>
            </Box>
            <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Gallery
                </Typography>
                <Paper sx={{ padding: "32px", width: "60%" }}>
                    <input ref={GfileRef} multiple={true} type="file" onChange={(e) => { handleGalleryUpload(e) ; }} style={{ display: "none" }} />
                    <Box
                        onClick={() => { GfileRef.current && GfileRef.current.click() }}
                        sx={{ display: "flex", flexDirection: 'column', gap: "16px", border: "1px dashed grey", height: "150px", justifyContent: "center", alignItems: "center" }}>

                        <BackupIcon sx={{ color: "grey.500", fontSize: "32px", }} />
                        <Typography sx={{ color: "primary.dark" }} >Upload an Image</Typography>
                        <Typography sx={{ color: "grey.800" }} >PNG,JPG</Typography>

                    </Box>

                    <Grid container direction="row" sx={{gap:"1rem",pt:"20px"}}>
                        
                        {
                            product?.up_gallery?.length>=1 && product.up_gallery.map((img,img_id)=>{
                                
                                return(
                                    img && <Grid item key={img_id}>

                                        <Chip label={img&&img.split(`products-media/${spefic_prod.created_by+"/"+spefic_prod.slug+"/gallery/"}`)[1]} deleteIcon={<Close/>} onDelete={()=> handleUpdateGallleryImgs(true,img_id)}/>
                                    </Grid>
                                )
                            })
                        }
                        {
                            newlyUploadedFiles.map((name,name_i)=>{
                                return(
                                    name && <Grid item key={name_i}>
                                        <Chip label={name} deleteIcon={<Close/>} onDelete={()=> handleUpdateGallleryImgs(false,name_i)}/>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                </Paper>
            </Box>
            <Box sx={{ borderBottom: "1px dashed grey ", paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Description
                </Typography>
                <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                    <Box>
                        <Typography sx={formStyle.label} color="grey.700" >Name</Typography>
                        <InputBase sx={formStyle.inputBase} value={product.name} onChange={(e) => dispatch(updateProduct({ ...product, name: e.target.value }))} />
                        {errors.name && <Typography sx={{ color: "error.light" }}>{errors.name}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Slug Name</Typography>
                        <InputBase sx={formStyle.inputBase} disabled value={product.slug} onChange={(e) => dispatch(updateProduct({ ...product, slug: e.target.value }))} />
                        
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Unit</Typography>
                        <InputBase sx={formStyle.inputBase} value={product.unit} onChange={(e) => dispatch(updateProduct({ ...product, unit: e.target.value }))} />
                        {errors.unit && <Typography sx={{ color: "error.light" }}>{errors.unit}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Brief <Typography component="span" color="error">*</Typography></Typography>

                        <InputBase multiline rows={5} sx={formStyle.textBox} value={product.details} onChange={(e) => dispatch(updateProduct({ ...product, details: e.target.value }))} />
                        

                        {errors.description && <Typography sx={{color:"error.light"}}>{errors.description}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Description</Typography>

                        
                        <Editor  style={formStyle.textBox}  value={product.description} onTextChange={(e) => dispatch(updateProduct({ ...product, description: e.htmlValue }))} />

                        
                    </Box>
                </Paper>
                
            </Box>
            <Box sx={{ paddingBottom: "45px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>


                <Typography sx={[Styles.subheader, { alignSelf: "start" }]}>
                    Simple Product Information
                </Typography>
                <Paper sx={{ padding: "32px", display: "flex", flexDirection: "column", gap: "36px", width: "60%" }}>

                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Price</Typography>
                        <InputBase sx={formStyle.inputBase} value={product.price} onChange={(e) => dispatch(updateProduct({ ...product, price: e.target.value }))} />
                        {errors.price && <Typography sx={{ color: "error.light" }}>{errors.price}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Sale Price</Typography>
                        <InputBase sx={formStyle.inputBase} value={product.selling_price} onChange={(e) => dispatch(updateProduct({ ...product, selling_price: e.target.value }))} />
                        {errors.sellingPrice && <Typography sx={{ color: "error.light" }}>{errors.sellingPrice}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Quantity</Typography>
                        <InputBase sx={formStyle.inputBase} value={product.available_quantity} onChange={(e) => dispatch(updateProduct({ ...product, available_quantity: e.target.value }))} />
                        {errors.quantity && <Typography sx={{ color: "error.light" }}>{errors.quantity}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">SKU</Typography>
                        <InputBase sx={formStyle.inputBase} value={product.sku} onChange={(e) => dispatch(updateProduct({ ...product, sku: e.target.value }))} />
                        
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Width <Typography component="span" color="error">*</Typography></Typography>
                        <InputBase required sx={formStyle.inputBase} value={product.width} onChange={(e) => dispatch(updateProduct({ ...product, width: e.target.value }))} />
                        {errors.width && <Typography sx={{color:"error.light"}}>{errors.width}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Height <Typography component="span" color="error">*</Typography></Typography>
                        <InputBase required sx={formStyle.inputBase} value={product.height} onChange={(e) => dispatch(updateProduct({ ...product, height: e.target.value }))} />
                        {errors.height && <Typography sx={{color:"error.light"}}>{errors.height}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Length <Typography component="span" color="error">*</Typography></Typography>
                        <InputBase required sx={formStyle.inputBase} value={product.length} onChange={(e) => dispatch(updateProduct({ ...product, length: e.target.value }))} />
                        {errors.length && <Typography sx={{color:"error.light"}}>{errors.length}</Typography>}
                    </Box>
                    <Box>
                        <Typography sx={formStyle.label} color="grey.700">Tax</Typography>
                        <InputBase sx={formStyle.inputBase} value={product.tax} onChange={(e) => dispatch(updateProduct({ ...product, tax: e.target.value }))} />
                        {errors.tax && <Typography sx={{ color: "error.light" }}>{errors.tax}</Typography>}
                    </Box>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        sx={{ flexDirection: "row" }}
                        value={product.status?product.status:'draft'}
                        onChange={(e) => dispatch(updateProduct({ ...product, status: e.target.value }))}
                    >
                        <FormControlLabel value="draft" control={<Radio />} label="Draft" />
                        <FormControlLabel value="publish" control={<Radio />} label="Publish" />
                    </RadioGroup>
                    {errors.status && <Typography sx={{ color: "error.light" }}>{errors.status}</Typography>}
                </Paper>
            </Box>

            <Box sx={{ alignSelf:"end" }}>
                <Button sx={formStyle.button} variant="contained" onClick={handleUpdateProduct}>Update</Button>
            </Box> */}
        
        {/*------------- old code--------------------*/}

    </Box>)

}

export default ProductUpdateForm 