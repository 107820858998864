import { Box, ListItem, List, ListItemButton, ListItemText, Collapse, ListItemIcon, ListSubheader, Icon } from "@mui/material"
import { Settings, StarBorder } from "@mui/icons-material"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useState } from "react";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveSideBAr } from "../redux/active_bar";
import { updateListSelect } from "../redux/user_reducer";
import dashboard_svg from "../assets/adminSidebar/Dashboard.svg"
import inventory_svg from "../assets/adminSidebar/Inventory.svg"
import orders_svg from "../assets/adminSidebar/Orders.svg"
import paymentHistory_svg from "../assets/adminSidebar/Payment History.svg"
import products_svg from "../assets/adminSidebar/Products.svg"
import userManagment_svg from "../assets/adminSidebar/User Management.svg"
import { updateInventary,updateInventoryData } from "../redux/admin";
import CustomAxios from "../utils/CustomAxios";
import { useEffect } from "react";


const AdminSidebar = () => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const list_select = useSelector(state => state.user.listitem)
    const inventary=useSelector(state=>state.admin.inventary)
    const inventoryForm=useSelector(state=>state.admin.inventoryForm)
    const dispatch = useDispatch()
    // const handleClick = () => {
    //     setOpen(!open);
    // };
    useEffect(() => {
        CustomAxios.get("inventory/").then((result) => {
            console.log(result)
            dispatch(updateInventoryData(result.data))
        }).catch((e) => console.log(e))
    }, [inventoryForm])
    const handleListItemClick = (e, list) => {
        e.preventDefault();
        // console.log(list)
        // handleClick()
        
        if (list == "/pay" || list == '/user' || list == '/dash') {
            navigate('/product')
            dispatch(updateInventary(false))

            dispatch(updateListSelect(false))
        }
        else {
            navigate(list)
        }
    };



    return (
        <Box sx={{ minWidth: "303px", bgcolor: "#fff",  position:"sticky", top:"144px",height:"max-content"}}>
            <List
                sx={{ width: '100%', maxWidth: 360, }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/product" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/product')}>
                    <ListItemIcon>
                        <Box component="img" src={dashboard_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
                {/* <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/dash" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/dash')}>
                    <ListItemIcon>
                        <Box component="img" src={products_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="All Products" /> */}
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                {/* </ListItemButton> */}
                <ListItemButton component={Link} to="/inventry" sx={{ color: list_select == "/inventry" ? "primary.light" : "grey.900" }} onClick={(e) => {handleListItemClick(e, '/inventry');dispatch(updateInventary(!inventary))}}>
                    <ListItemIcon>
                        <Box component="img" src={inventory_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Inventory" />
                    {inventary ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={inventary} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                {/* <StarBorder /> */}
                            </ListItemIcon>
                            <ListItemText primary="Overview" />
                        </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                {/* <StarBorder /> */}
                            </ListItemIcon>
                            <ListItemText primary="Stock List" component={Link} to="/stocklist" sx={{ color: list_select == "/stocklist" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/stocklist')} />
                        </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/product" onClick={(e) => handleListItemClick(e, '/dash')} >
                            <ListItemIcon>
                                {/* <StarBorder /> */}
                            </ListItemIcon>
                            <ListItemText primary="Products" />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton component={Link} to="/orders" sx={{ color: list_select == "/orders" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/orders')}>
                    <ListItemIcon>
                        <Box component="img" src={orders_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Orders" />
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
                <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/pay" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/pay')}>
                    <ListItemIcon>
                        <Box component="img" src={paymentHistory_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Payment History" />
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
                <ListItemButton component={Link} to="/commision-history" sx={{ color: list_select == "/commision-history" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/commision-history')}>
                    <ListItemIcon>
                        <Box component="img" src={paymentHistory_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Commision History" />
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
                <ListItemButton component={Link} to="/Withdraw-request" sx={{ color: list_select == "/Withdraw-request" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/Withdraw-request')}>
                    <ListItemIcon>
                        <Box component="img" src={paymentHistory_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Withdraw Request" />
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
                {/* <ListItemButton component={Link} to="/product" sx={{ color: list_select == "/user" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/user')}>
                    <ListItemIcon>
                        <Box component="img" src={userManagment_svg} sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="User Management " /> */}
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                {/* </ListItemButton> */}
                <ListItemButton component={Link} to="/delivery-settings" sx={{ color: list_select == "/user" ? "primary.light" : "grey.900" }} onClick={(e) => handleListItemClick(e, '/delivery-settings')}>
                    <ListItemIcon>
                        <Settings sx={{ width: "20px", height: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary="Settings " />
                </ListItemButton>
                {/* <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            
                            <ListItemText primary="All Products" />
                        </ListItemButton>
                        
                    </List>
                </Collapse> */}
            </List>
        </Box>
    )

}
export default AdminSidebar