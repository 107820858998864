import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Box,
  Grid,
  Typography,
//  Link,
  IconButton,
  makeStyles,
  styled,
  MenuList,
  MenuItem,
  Stack,
  Divider,
  createTheme
} from '@mui/material';
import { Twitter, LinkedIn, FacebookRounded,Instagram } from '@mui/icons-material';
import razorpay_img from "../assets/razorpay-icon.png"
import YouTubeIcon from '@mui/icons-material/YouTube';
const CustomLink = styled(Link)((theme) => ({
  color:"#000",
  textDecoration:"none",
  fontSize:"12px",
  fontFamily: "'Poppins',sans-serif",
  ":hover":{
    color:"rgb(39,162,253,1)",
    textDecoration:"none",
     
  }
}))

const CustomMenuItem = styled(MenuItem)((theme)=>({
  padding:0,
  ":hover":{
    backgroundColor:"transparent"
  }
}))


const useStyles = styled((theme) => ({
  paymentIcon: {
    width: '40px',
    height: '40px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    border: '1px solid #e7e7e7',
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
  },
  paymentIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  linkList: {
    color:"#777777",
    listStyleType: 'none',
    padding: 0,
    '& li': {
      marginBottom: theme.spacing(1),
    },
  },
  
}));




const Footer = ()=> {
  const authtoken = localStorage.getItem('authtoken');
  const styles = {
    footerWrapper: {
      backgroundColor: '#FFF',
      // padding: theme.spacing(4, 0),
    },
    heading: {
      color:"#222529",
      fontSize: "17.01px",
      fontWeight: 700,
      lineHeight: "1em",
      letterSpacing: "-0.05em",
      marginBottom: "18px",
    },
    headers:{
      color:"#000",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "1em",
      // letterSpacing: "-0.05em",
      // marginBottom: "18px",
      fontFamily: "'Poppins',sans-serif",

    },
    paymentIcon: {
      display:"inline-flex",
      verticalAlign:"middle",
      width: '55px',
      height: '32px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      border: '1px solid #e7e7e7',
      borderRadius:"4px",
      color:"#000",
      backgroundColor: '#fff',
      margin:"0 1px 5px"
    },
    paymentIcons: {
      display: 'flex',
      alignItems: 'center',
    },
  }
  
  return (
    <Box  sx={[styles.footerWrapper,{width:"100%",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",background:"#F9F9FC",zIndex:2}]}>
      <Box sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"space-between",padding:{xs:"24px 16px" ,md:"64px 120px"}}}>
         <Box sx={{width:"100%",padding:{xs:0 ,sm:0,md:0,lg:0}}}>
        <Grid container  sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <Grid item sm={12} md={6} style={{width:"100%"}}>
            <Typography  sx={[styles.headers,{lineHeight:"44px",fontSize:"20px", fontFamily: "'Poppins',sans-serif",}]}>
              RESOURCES
            </Typography>
            <Box  gap={{xs:"8px",md:"16px"}}  sx={{display:"flex",alignItems:"center",justifyContent:"flex-start",flexWrap:"nowrap",overflow:{xs:"scroll",md:"visible"},"&::-webkit-scrollbar":{display:"none"}, scrollBehavior:"smooth",}}>
              <Box  sx={{bgcolor:{xs:"black",md:"transparent"},padding:"4px 12px", height:"26px",borderRadius:"10px",flexShrink:0}}>
                  <CustomLink to="https://help.mayiiq.com/" target="_blank" sx={{color:{xs:"white",md:"black"},display:"flex",alignItems:"center"}}>
                    Help & FAQs
                  </CustomLink>
              </Box>

              <Divider  orientation='vertical' sx={{height:"10px",border:"1px solid #ddd",display:{xs:"none",md:"block"}}}/>
              
              <Box   sx={{bgcolor:{xs:"black",md:"transparent"},padding:{xs:"4px 12px",md:"4px 0px"}, height:"26px",borderRadius:"10px",flexShrink:0}}>
                {/* <CustomMenuItem> */}
                  <CustomLink to="/terms-service" sx={{color:{xs:"white",md:"black"},display:"flex",alignItems:"center"}}>
                    Terms of Service
                  </CustomLink>
                {/* </CustomMenuItem> */}
              </Box>
              
              <Divider  orientation='vertical' sx={{height:"10px",border:"1px solid #ddd",display:{xs:"none",md:"block"}}}/>

              <Box   sx={{bgcolor:{xs:"black",md:"transparent"},padding:"4px 12px", height:"26px",borderRadius:"10px",flexShrink:0}}>
                {/* <CustomMenuItem> */}
                  <CustomLink to="/privacy-policy" sx={{color:{xs:"white",md:"black"},display:"flex",alignItems:"center"}} >
                    Privacy Policy
                  </CustomLink>
                {/* </CustomMenuItem> */}
              </Box>
              
              <Divider  orientation='vertical' sx={{height:"10px",border:"1px solid #ddd",display:{xs:"none",md:"block"}}}/>

              <Box   sx={{bgcolor:{xs:"black",md:"transparent"},padding:"4px 12px", height:"26px",borderRadius:"10px",flexShrink:0}}>
                {/* <CustomMenuItem> */}
                  <CustomLink to="/refund-policy" sx={{color:{xs:"white",md:"black"},display:"flex",alignItems:"center"}} >
                    Returns Policy
                  </CustomLink>
                {/* </CustomMenuItem> */}
              </Box>

              <Divider  orientation='vertical' sx={{height:"10px",border:"1px solid #ddd",display:{xs:"none",md:"block"}}}/>

              <Box   sx={{bgcolor:{xs:"black",md:"transparent"},padding:"4px 12px", height:"26px",borderRadius:"10px",flexShrink:0}}>
                {/* <CustomMenuItem> */}
                  <CustomLink to="/shipping-policy"  sx={{color:{xs:"white",md:"black"},display:"flex",alignItems:"center"}} >
                    Shipping Policy
                  </CustomLink>
                {/* </CustomMenuItem> */}
              </Box>
            </Box>
          </Grid>

          <Box  sx={{textAlign:{xs:"start",},alignItems:"center",justifyContent:{xs:"start",sm:"center"}}}>
            <Typography sx={[styles.headers,{fontSize:"20px",lineHeight:"44px"}]}>
              SOCIAL MEDIA
            </Typography>
            <Box sx={{display:"flex",gap:"10px"}}>

            <IconButton
              component="a"
              href="https://www.facebook.com/people/Mayi-IQ/61559304845754/?mibextid=LQQJ4d"
              target="_blank"
              title="Facebook"
              sx={{padding:"0px"}}
            >
              <FacebookRounded  sx={{fontSize:"32px",padding:"0px",color:"#000"}} />
            </IconButton>
            <IconButton
              component={"a"}
              href="https://www.instagram.com/mayi_iq/?igsh=NmRnbzc3NzZhdXVk&utm_source=qr"
              target="_blank"
              title="Instagram"
              sx={{padding:"0px"}}
            >
              <Instagram  sx={{fontSize:"32px",padding:"0px",color:"#000"}} />
            </IconButton>
            <IconButton
              component={"a"}
              href="https://www.youtube.com/@MAYIIQ2024"
              target="_blank"
              title="Youtube"
              sx={{padding:"0px"}}
            >
              <YouTubeIcon  sx={{fontSize:"32px",padding:"0px",color:"#000"}} />
            </IconButton>
            </Box>
          
          </Box>
          {/* <Grid item xs={12} sm={6} md={3} sx={{p:"12px",textAlign:"center",display:"flex",alignItems:{xs:"start",sm:"flex-end"},justifyContent:"end",flexDirection:"column"}}>
            <Typography   sx={[styles.headers,{mb:"21px",fontSize:"17.01px"}]}>
              PAYMENT METHODS
            </Typography>
            <Stack direction="row" sx={[styles.paymentIcons,{justifyContent:"center",alignItems:"center"}]}>
              <Box component="img" src={razorpay_img} sx={{height:"24px"}}/>
            </Stack>
          </Grid> */}
        </Grid>
        {/* <Divider sx={{border:"0.25px solid #ddd"}}/> */}
        
      </Box>
      </Box>
      <Grid container  sx={{bgcolor:"#000",marginBottom:{xs:authtoken?"72px":"0px",md:"0px"}}}>
          <Grid item xs={12}>
            <Typography align="center" variant="subtitle1" sx={{ fontFamily: "'Poppins',sans-serif",color:"primary.contrastText",lineHeight:{xs:"20px", md:"44px"},fontSize:{xs:"9px",md:"14px"}}}>
            MAYI IQ AI TECHNOLOGIES LLP © Copyright {new Date().getFullYear()}. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
     
    </Box>
  );
}

export default Footer;
