import { Modal } from "@mui/material"
import { useSelector } from "react-redux"
import InventoryForm from "./inventoryForm"



const InventoryModal = () => {

    const inventoryForm=useSelector(state=>state.admin.inventoryForm)

    return <Modal

        open={inventoryForm}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
            <InventoryForm/>

    </Modal>

}

export default InventoryModal