import { Box, Typography, Modal, InputBase, Button } from "@mui/material"
import { updateBank, updateBankModal,updateGetBank,updateIsProfileReloaded,updateWithdrawRequest } from "../redux/user_reducer";
import { formStyle } from "./profile";

import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "react-responsive";
import { useState } from "react";
import axios from "axios";
import CustomAxios from "../utils/CustomAxios";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
const BankForm = () => {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
    const open = useSelector(state => state.user.bankModal)
    const bank=useSelector(state=>state.user.bank)
    let id = parseInt(useSelector(state => state.user.id))
    const [errors,setErrors]=useState({})
    const [isVerified, setIsVerified] = useState(true);
    const allBank=useSelector(state=>state.user.allBank)
    const [notDelete,setNotDelete]=useState(false)

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(updateBankModal(false))
        setNotDelete(false)
        setErrors({})
        // setIsVerified(true)
        // setOpen(false);
    }

    const checkDuplicate = () =>{
        if(allBank && allBank.length>=1){
            let data = allBank.filter((e)=>e.account_number === bank.account_number && e.ifsc_code === bank.ifsc_code)
            if(data && data.length>=1){
                dispatch(updateSnackBarMessage("Account details existed."))
                dispatch(updateSnackBarSeverity("error"))
                dispatch(updateSnackBarOpen(true))
                return false
            } 
        }
        return true
    }

    const handleVerifyBank = (type)=>{
        
        let res=''
        const validate = validation(bank)
        if(validate){
            const duplicate = checkDuplicate()

            if(type !== "del" && duplicate){
    
                CustomAxios.post('verifybank/',bank).then((res)=>{
                    let data = res.data
                    console.log(data)
                    if(data.verification_result && !data.verification_result?.error){
        
                        dispatch(updateBank({ ...bank, account_name: data?.verification_result?.name_at_bank, bank_name: data?.verification_result?.bank_name, branch_name: data?.verification_result?.branch }))
                        
                        res = {"status":true, data:{account_name: data?.verification_result?.name_at_bank, bank_name: data?.verification_result?.bank_name, branch_name: data?.verification_result?.branch}}
                        // handleSubmit(type,res)
                        setIsVerified(false)
                        dispatch(updateSnackBarMessage(data.message))
                        dispatch(updateSnackBarSeverity('success'))
                        dispatch(updateSnackBarOpen(true))
                    }
                    else{
                       
                        dispatch(updateSnackBarMessage(data.message))
                        dispatch(updateSnackBarSeverity('error'))
                        dispatch(updateSnackBarOpen(true))
                        res = {"status":false,data:null}
                    }
                }).catch((err)=>{
                    dispatch(updateSnackBarMessage("Something went wrong."))
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarOpen(true))
                    res = {"status":false,data:null}
                })
            }
            else{
                handleSubmit(type,{"status":true,data:null})
            }
        }
    }

    const handleSubmit=(val)=>{
        // if (!isVerified){
        //     handleVerifyBank();
        //     return
        // }
        if(val!=="del"){
            const validate = validation(bank)

            if (validate){
                // const res = handleVerifyBank()
                // if(res.status){
    
                const payload={
                    bank_name:bank.bank_name,
                    account_number:bank.account_number,
                    ifsc_code:bank.ifsc_code,
                    branch_name:bank.branch_name,
                    account_name:bank.account_name,
                    user:id,
                    created_by:id,
                }
                if(bank.id){
                    payload['id'] = bank.id
                }
                switch (val) {
                    case 'submit':
                        addBankDetails(payload).then(()=> {
                        dispatch(updateBankModal(false))
                        })
                        break;
                    case 'update':
                        CustomAxios.put(`bank_details/${bank.id}/`,payload).then((res)=>{
                            dispatch(updateBankModal(false))
                            dispatch(updateIsProfileReloaded(true))
                        })
                        break;
                    default:
                        break;
                }
            }
        }
        else{
            const payload={
                
                status:"disactive"
                
            }
            CustomAxios.put(`bank_details/${bank.id}/`,payload).then((res)=>{
                console.log("????????????????????????????/",res.data.status)
                if (res.data.status=="active"){
                    setNotDelete(true)

                }
                else{
            dispatch(updateBankModal(false))
            dispatch(updateIsProfileReloaded(true))
                }
               
            })
        }

            setIsVerified(true)
            // }

    }
    const addBankDetails=async(bank)=>{
        const payload={
            bank_name:bank.bank_name,
            account_number:bank.account_number,
            ifsc_code:bank.ifsc_code,
            branch_name:bank.branch_name,
            account_name:bank.account_name,
            user:id,
            created_by:id
        }
        CustomAxios.post("add_bank_details/",payload).then(res=>{
            // console.log(res)
            dispatch(updateBankModal(false))
            dispatch(updateIsProfileReloaded(true))
            // CustomAxios.post(`get_details/`, payload).then(response => {
            //     // dispatch(updateMemberResponse(response.data))
            //     dispatch(updateGetBank(response.data.bankdetails))
            //     dispatch(updateWithdrawRequest(response.data.withdrawRequest))
                
            // })
        })
        

    }
    const validation=(bank)=>{
        let newErrors={}
        let valid=true


        // if (!bank.account_name?.trim()) {
        //     newErrors.account_name = 'Please Enter Your Name';
        //     valid = false;
        // }
        if (!bank.account_number?.trim()) {
            newErrors.account_number = 'Please Enter Your Account Number';
            valid = false;
        }
        if (!bank.confirmAcc_number?.trim()) {
            newErrors.confirmAcc_number = 'Please Enter Your Confirm Account Number';
            valid = false;
        }
        if(!bank.ifsc_code?.trim()){
            newErrors.ifsc_code = 'Please Enter Your IFSC Code. ';
            valid = false;
        }
        else if(bank.ifsc_code && bank.ifsc_code.length !== 11){
            newErrors.ifsc_code = 'Please Enter Valid IFSC Code. ';
            valid = false;
        }
        // if (!bank.bank_name?.trim()) {
        //     newErrors.bank_name = 'Please Enter Your Bank Name';
        //     valid = false;
        // }
        if (bank?.account_number?.trim()!==bank.confirmAcc_number?.trim()){
            newErrors.confirmAcc_number = 'Your Account Number And Confirm Account Number Not Same';
            valid = false;
        }
        setErrors(newErrors);

        return valid;
    }

    const handleChange = ()=>{
        if(isVerified === false){
            const validate = validation(bank)
            if(validate){

                handleVerifyBank('update')
            }
        }
    }

    const handleBackBtn = ()=>{
        setIsVerified(true)
        !bank?.id && dispatch(updateBank({ ...bank, account_name: '', bank_name: '', branch_name: '' }))
    }

    return (<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ zIndex: "21 !important" }}
    >
        <Box sx={{ width:{xs:"calc(100% - 64px)",md : "600px"}, background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none", overflowY: "scrollY" }}>
            <Typography sx={{ textAlign: "center", paddingBottom: "10px" }}>Add Bank Details</Typography>
            <Box sx={{ display:"flex",flexDirection:"column",width: "100%", gap: "24px" }}>

                {isVerified && <>
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={formStyle.label} color="grey.700"> Account Number <Typography component="span" sx={{ color: "#EF4444" }}>*</Typography></Typography>
                        <InputBase disabled={bank?.id?true:false} type="tel" sx={formStyle.inputBase} value={bank.account_number} placeholder="Eg: 1234567890" onChange={(e) => {dispatch(updateBank({ ...bank, account_number: e.target.value.replace(/[^0-9]/g, '') })); handleChange()}}/>
                        {errors.account_number && <Typography sx={{ color: "error.light" }}>{errors.account_number}</Typography>}
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={formStyle.label} color="grey.700">Confirm Account Number <Typography component="span" sx={{ color: "#EF4444" }}>*</Typography></Typography>
                        <InputBase disabled={bank?.id?true:false} type="tel"  sx={formStyle.inputBase} value={bank.confirmAcc_number} placeholder="Eg: 1234567890" onChange={(e) => dispatch(updateBank({ ...bank, confirmAcc_number: e.target.value.replace(/[^0-9]/g, '') }))}/>
                        {errors.confirmAcc_number && <Typography sx={{ color: "error.light" }}>{errors.confirmAcc_number}</Typography>}
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={formStyle.label} color="grey.700">IFSC Code <Typography component="span" sx={{ color: "#EF4444" }}>*</Typography></Typography>
                        <InputBase disabled={bank?.id?true:false} sx={formStyle.inputBase} value={bank.ifsc_code} placeholder="Eg: IDIB000S308" onChange={(e) => dispatch(updateBank({ ...bank, ifsc_code: e.target.value.replace(" ","") }))}/>
                        {errors.ifsc_code && <Typography sx={{ color: "error.light" }}>{errors.ifsc_code}</Typography>}
                        
                    </Box>
                </>
                }

                {bank?.id || (bank.account_name && bank.bank_name ) ?<>
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={formStyle.label} color="grey.700">Account Holder Name</Typography>
                        <InputBase disabled sx={formStyle.inputBase} value={bank.account_name}   onChange={(e) => dispatch(updateBank({ ...bank, account_name: e.target.value }))} />
                        {errors.account_name && <Typography sx={{ color: "error.light" }}>{errors.account_name}</Typography>}
                    </Box>
                    
                    <Box sx={{ width: "100%" }}>
                        <Typography sx={formStyle.label} color="grey.700">Bank Name </Typography>
                        <InputBase disabled sx={formStyle.inputBase} value={bank.bank_name}  onChange={(e) => dispatch(updateBank({ ...bank, bank_name: e.target.value }))}/>
                        {errors.bank_name && <Typography sx={{ color: "error.light" }}>{errors.bank_name}</Typography>}
                        {bank.branch_name && <Typography sx={{ fontSize:"12px",pl:"10px" }}>{bank.branch_name}</Typography>}
                    </Box>
                </>:null}
                {
                    notDelete&&<Typography sx={{ color: "error.light" }}>you have a Withdrawal request for this account. You cannot delete this bank account before your request complete.</Typography>
                }

                <Box sx={{ display: "flex", width: "100%", gap: "24px", justifyContent: "space-between" }}>
                    {/* {address.id&&<Button sx={ { height: "3rem",padding:"0rem 1.25rem",textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end",borderRadius:"5px" }} color="error" variant="contained" >Delete</Button>} */}
                    <Box sx={{ display: "flex", gap: "8px" }}>
                        {
                            isVerified == false?
                            <Button sx={{ height: "3rem", padding: "0rem 1.25rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end", borderRadius: "5px" }} color="primary" variant="outlined" onClick={()=>{handleBackBtn()}} >Back</Button>
                            :
                            <Button sx={{ height: "3rem", padding: "0rem 1.25rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end", borderRadius: "5px" }} color="primary" variant="outlined" onClick={handleClose} >Cancel</Button>
                        }
                        {/* {
                            bank?.id && <Button sx={{ height: "3rem", padding: "0rem 1.25rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end", borderRadius: "5px" }} color="error" variant="contained" onClick={()=>handleSubmit("del")}>Delete</Button>
                        } */}

                    </Box>
                    {
                        isVerified && !bank.id ?
                        <Button sx={{ height: "3rem", padding: "0rem 1.25rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end", borderRadius: "5px" }} color="primary" variant="contained" onClick={()=>handleVerifyBank()}>Verify</Button>
                        :
                        bank?.id ? 
                        <Button sx={{ height: "3rem", padding: "0rem 1.25rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end", borderRadius: "5px" }} color="error" variant="contained" onClick={()=>handleSubmit("del")}>Delete</Button>
                        // <Button sx={{ height: "3rem", padding: "0rem 1.25rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end", borderRadius: "5px" }} color="primary" variant="contained" onClick={() => handleSubmit("update")}>Update</Button>
                        :
                        <Button sx={{ height: "3rem", padding: "0rem 1.25rem", textTransform: "none", fontWeight: 600, fontSize: "14px", maxWidth: "150px", alignSelf: "flex-end", borderRadius: "5px" }} color="primary" variant="contained" onClick={() => handleSubmit("submit")}>Submit</Button>
                    }

                </Box>
            </Box>

        </Box>
    </Modal>)



}
export default BankForm